import React from 'react';
import {ExcelRenderer} from 'react-excel-renderer';
import {formatData, validateData} from './Upload.js';
import db from './db.js';
import {formValues} from './uiSharedResources.js';
import {Button, Form, Col, Row} from 'react-bootstrap';
import {UserContext} from './UserContext.js';

async function submitUpdates(data, showError) {
  return await db('import', 'PUT', data, showError);
}

export default class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: null,
      closets: null,
      networks: null,
      portSpeeds: null,
      duplexes: null,
      connections: null,
      mode: 'full',
      metaData: [],
      rows: [],
    };
    this.validateRows = this.validateRows.bind(this);
    this.prepareData = this.prepareData.bind(this);
    this.showReport = this.showReport.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    const {showError} = this.props;
    formValues(showError).then(v => {
      if (v != null) {
        const buildings = v.buildings.rows;
        const closets = v.closets.rows;
        const networks = v.networks.rows;
        const portSpeeds = v.portSpeeds.rows;
        const duplexes = v.duplexes.rows;
        const connections = v.connections.rows;
        const statuses = v.statuses.rows
        this.setState({buildings, closets, networks, portSpeeds, duplexes, connections, statuses});
      }
    });
  }

  validateRows(data) {
    const {buildings, closets, networks, portSpeeds, duplexes, connections, statuses} = this.state;
    const ref = {buildings, closets, networks, portSpeeds, duplexes, connections, statuses};
    return validateData(data, ref);
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const state = this.state;
    state[name] = value;
    this.setState(state);
  }

  prepareData(validRows, errorRows, errorSummary) {
    const {metaData, mode} = this.state;
    const data = {};
    switch (mode) {
      case 'summary':
        data.metaData = [{name: 'CUTSHEET_ID'}, {name: 'ERRORS'}];
        data.rows = errorSummary;
        break;
      case 'full':
        metaData.push({name: 'ERRORS'});
        data.metaData = metaData;
        data.rows = errorRows.concat(validRows);
        break;
      default:
    }
    return data;
  }

  showReport(data, errorSummary) {
    const {showError} = this.props;
    showError(`Found errors in ${errorSummary.length} rows. Please fix and try again.`);
    this.props.history.push({
      pathname: '/results',
      state: {
        data: data,
        query: {},
        startPage: '/upload',
      },
    });
  }

  fileHandler = (event) => {
    const fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const {metaData, rows, error} = formatData(resp);
        if (error) {
          const {showError} = this.props;
          showError(error);
        } else {
          this.setState({
            metaData: metaData,
            rows: rows,
          });
        }
      }
    });
  };

  async onSubmit(e) {
    e.preventDefault();
    const {rows} = this.state;
    const {showSuccess, showError} = this.props;
    const {validRows, errorRows, errorSummary} = this.validateRows(rows);
    const data = this.prepareData(validRows, errorRows, errorSummary);

    if (errorSummary.length > 0) {
      this.showReport(data, errorSummary);
    } else {
      showSuccess(`No errors found in ${validRows.length} records. Submitting updates to database.`);
      const {username} = this.context;
      const ignoredRows = ['LAST_MODIFIED_DATE', 'BUILDING_NAME', 'CLOSET_NUMBER', 'PATCH_PANEL', 'PATCH_PANEL_PORT'];
      for (const row of validRows) {
        row.USERNAME = username;
        // delete row.LAST_MODIFIED_DATE;
        ignoredRows.forEach(field => delete row[field]);
      }
      const response = await submitUpdates(validRows, showError);
      showSuccess(response);
    }
  }

  render() {
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <h1>Upload</h1>
          <Form onSubmit={this.onSubmit}>
            <Form.Group as={Row}>
              <Col>
                <input type="file" onChange={this.fileHandler} name={'file'}/>
              </Col>
            </Form.Group>
            <div>Note: If errors are found, you will be shown an error report instead.</div>
            <Form.Group as={Row} className={'align-items-center'}>
              <Col sm={'auto'}>
                <Form.Label>Select Report Type</Form.Label>
              </Col>
              <Col sm={'auto'}>
                <Form.Control as={'select'} onChange={this.onChange} custom name={'mode'}>
                  <option value={'full'}>Full Report with Errors Column</option>
                  <option value={'summary'}>Error Summary Report</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col>
                <Button
                    variant="primary"
                    size="sm"
                    type="submit"
                >
                  Process Upload
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
    );
  }
}
Upload.contextType = UserContext;