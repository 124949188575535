import React from 'react';
import './Contents.css';
import db from './db.js';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {UserContext} from './UserContext';

async function formValues(table, showError) {
  const vals = await db('form-values', 'GET', {table: table}, showError);
  return vals != null ? vals : null;
}

async function runQuery(query, showError) {
  const vals = await db('cutsheet-notes', 'GET', query, showError);
  return vals != null ? vals : null;
}

function listify(menuItems, id, name) {
  if (!menuItems) return null;
  const parsedList = menuItems.map((item) => (
      <option key={item[id]} value={item[id]}>{item[name]}</option>
  ));
  return [<option key="0" value="0"/>].concat(parsedList);
}

export default class CutsheetNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        building_id: '',
        closet_id: '',
      },
      buildings: [],
      closets: [],
      allClosets: [],
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.loadBuildings = this.loadBuildings.bind(this);
    this.loadClosets = this.loadClosets.bind(this);
    this.loadPassedQuery = this.loadPassedQuery.bind(this);
  }

  loadBuildings() {
    const {showError} = this.props;
    formValues('building', showError).then(response => {
      if (response !== null && response !== undefined) {
        const buildings = listify(response.rows, 'BUILDING_ID', 'BUILDING_NAME');
        this.setState({buildings});
      }
    });
  }

  loadClosets() {
    const {showError} = this.props;
    formValues('closet', showError).then(response => {
      if (response !== null && response !== undefined) {
        const allClosets = response.rows;
        this.setState({allClosets});
        if (this.state.query.building_id !== '') this.updateClosets(this.state.query.building_id);
      }
    });
  }

  updateClosets(value) {
    const allClosets = this.state.allClosets;
    const someClosets = allClosets.filter(c => c.BUILDING_ID === parseInt(value));
    const closets = listify(someClosets, 'CLOSET_ID', 'CLOSET_NUMBER');
    this.setState({closets});
  }

  loadPassedQuery() {
    if (this.props.location.state) {
      const query = this.state.query;
      const passedQuery = this.props.location.state.query;
      Object.keys(query).forEach(key => {
        query[key] = passedQuery[key];
      });
      this.setState({query});
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('advanced-reports')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    this.loadBuildings();
    this.loadClosets();
    this.loadPassedQuery();
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'building_id') this.updateClosets(value);
  }

  resetForm() {
    const query = this.state.query;
    Object.keys(query).forEach(key => {
      query[key] = '';
    });
    this.setState({query});
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showError} = this.props;
    const fullQuery = this.state.query;
    const query = {};
    Object.keys(fullQuery).forEach(key => {
      const value = fullQuery[key];
      if (value !== '' && value !== '0') query[key] = value;
    });
    runQuery(query, showError).then(res => {
      this.props.history.push({
        pathname: '/results',
        state: {
          data: res,
          query: query,
          startPage: '/cutsheetnotes',
        },
      });
    });
  }

  render() {
    return (
        <div style={{display: this.context.showNav('advanced-reports') ? 'block' : 'none'}}>
          <div className={'ContentTitle'}>Cutsheet Notes</div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId={'formBuldingName'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Building Name</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.building_id}
                              name={'building_id'} custom>
                  {this.state.buildings}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formClosetNumber'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Closet Number</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.closet_id}
                              name={'closet_id'} custom>
                  {this.state.closets}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Row>
              <Col>
                <Button onClick={this.resetForm}
                        variant="secondary"
                        size="sm"
                >
                  Reset Form
                </Button>
                <Button
                    className={'ml-3'}
                    variant={'primary'}
                    size={'sm'}
                    type={'submit'}
                >
                  Execute Query
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
CutsheetNotes.contextType = UserContext;