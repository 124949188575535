const urls = {
  dev: 'https://cutsheets.microservices-dev.northeastern.edu',
  test: 'https://api-test.northeastern.edu/cut-sheet-database',
  production: 'https://api.northeastern.edu/cut-sheet-database',
  default: 'https://cutsheets.microservices-dev.northeastern.edu/api',
  localhost: 'http://localhost:8099',
};

const validEnv = (env) => {
  return Object.keys(urls).includes(env);
}

const endpoints = (env) => {
  const url = validEnv(env) ? urls[env] : urls['default'];
  return {
    api_endpoints: {
      'form-values': url + '/api/form-values',
      'open-query': url + '/open-query',
      'open-query-full': url + '/open-query-full',
      'reference': url + '/reference',
      'new-panel': url + '/new-panel',
      'import': url + '/import',
      'patch-panels': url + '/cutsheet/patch-panel',
      'cutsheet': url + '/cutsheet',
      'cutsheet-notes': url + '/cutsheet-notes',
      'panel-ports': url + '/panel/ports',
      'switch': url + '/switch',
      'panel': url + '/panel',
      'update': url + '/update',
      'auth': url + '/auth',
      'users': url + '/users',
    }
  };
};

exports.get = function get(env) {
  return endpoints(env);
};
