import React from 'react';
import {Form} from 'react-bootstrap';
import ResultsTable from './ResultsTable.jsx';
import Button from 'react-bootstrap/Button';
import ReactExport from 'react-export-excel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {UserContext} from './UserContext.js';
import {formatDateField} from './uiSharedResources.js';

function Download({headers, rows}) {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  if (headers !== undefined && rows !== undefined) {
    const columns = headers.map(c => {
      return <ExcelColumn key={c.name} label={c.name} value={c.name}/>;
    });

    return (
        <ExcelFile
            filename="Cutsheet_download"
            element={<Button variant="success" size="sm" className="mb-2 mr-2" htmlFor="inlineFormCustomSelectPref">
              Download Results (xlsx)
            </Button>}>
          <ExcelSheet data={rows} name="data">
            {columns}
          </ExcelSheet>
        </ExcelFile>
    );
  }
}

function SavePDF({headers, rows, format, startPage}) {
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'in',
    format: format,
  });

  const dimensions = {
    letter: {width: 11, height: 8.5},
    legal: {width: 14, height: 8.5},
    ledger: {width: 17, height: 11},
  };

  const positions = {
    title: {x: dimensions[format].width / 2, y: 0.25},
    created: {x: 0.5, y: dimensions[format].height - 0.25},
    pageNum: {x: dimensions[format].width - 0.5, y: dimensions[format].height - 0.25},
  };

  let tableColumn = headers.map(h => h.name);
  const tableRows = [];
  rows.forEach(row => {
    const rowData = [];
    tableColumn.forEach(h => {
      rowData.push(row[h]);
    });
    tableRows.push(rowData);
  });

  tableColumn = headers.map(h => h.name.replaceAll('_', ' '));

  pdf.autoTable(tableColumn, tableRows, {startY: 0.5, styles: {fontSize: 6}});

  const pageCount = pdf.internal.getNumberOfPages();
  let title = 'Cutsheet Query Results';
  const date = new Date();
  let dateStamp = `Created: ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  // const title = startPage === '/cutsheetnotes' ? 'Notes Attachment' : '';
  if (startPage === '/cutsheetnotes') title = 'Notes Attachment';
  if (startPage === '/cutsheet') dateStamp += '  Note: * denotes a note entry in database';

  for (let i = 1; i <= pageCount; i++) {
    pdf.setPage(i);
    pdf.text(title, positions.title.x,
        positions.title.y, {align: 'center'});
    pdf.text(dateStamp, positions.created.x, positions.created.y);
    pdf.text(`Page ${i} of ${pageCount}`, positions.pageNum.x,
        positions.pageNum.y, {align: 'right'});
  }

  pdf.save('Cutsheet_download.pdf');
}

export default class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data || {},
      query: this.props.location.state.query || {},
      startPage: this.props.location.state.startPage || {},
      format: 'letter',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('user-management')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 500);
    const data = this.state.data;
    if (this.state.startPage !== '/upload') data.rows = formatDateField(data.rows);
    this.setState({data});

  }

  onChange(event, naturalValue) {
    const {value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    this.setState({format: value});
  }

  render() {
    if (this.state.data.status || this.state.data.rows === undefined) {
      return (
          <div>
            <h1>Search returned no results.</h1>
            <Button
                className="ExecuteQuery"
                variant="danger"
                size="sm"
                onClick={() => this.props.history.push({
                  pathname: this.state.startPage,
                  state: {
                    query: this.state.query,
                  },
                })
                }
            >
              Try Again
            </Button>
          </div>
      );
    } else {
      const {metaData, rows} = this.state.data;
      return (
          <div>
            <h1>Results</h1>
            <Form inline>
              <Download headers={metaData} rows={rows}/>
              <Button
                  onClick={() => SavePDF(
                      {headers: metaData, rows, format: this.state.format, startPage: this.state.startPage})}
                  variant="danger"
                  size="sm"
                  className="mb-2 mr-2"
              >
                Save Results to PDF
              </Button>
              <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                PDF Page Size:
              </Form.Label>
              <Form.Control as="select" onChange={this.onChange} className="my-1 mr-sm-2"
                            id="inlineFormCustomSelectPref" custom>
                <option value="letter">Letter (11" x 8.5")</option>
                <option value="legal">Legal (14" x 8.5")</option>
                <option value="ledger">Ledger (17" x 11")</option>
              </Form.Control>
              <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => this.props.history.push({
                    pathname: this.state.startPage,
                    state: {
                      query: this.state.query,
                    },
                  })
                  }
              >
                Go Back
              </Button>
            </Form>
            <ResultsTable headers={metaData} rows={rows}/>
          </div>
      );
    }
  }
}
Results.contextType = UserContext;