import React from 'react';
import './Contents.css';
import {Col, Form, InputGroup, FormControl, Button} from 'react-bootstrap';
import db from './db.js';
import {UserContext} from './UserContext.js';
import {makeSelectList, formValues} from './uiSharedResources.js';
import {ReferenceTableRecord} from './ReferenceTable.js';

export default class ReferenceAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: new ReferenceTableRecord(this.props.location.state.table, {}),
    };
    this.makeFieldRows = this.makeFieldRows.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // TODO: add validation to input fields
  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);

    const {table} = this.state.record;
    const {showError} = this.props;
    formValues(showError, table)
        .then(res => {
          const {record} = this.state;
          record.addValidation(res);
          this.setState({record});
        });

    if (table === 'closet') {
      formValues(showError, 'building')
          .then(res => {
            const {record} = this.state;
            record.addBuildingList(res);
            this.setState({record});
          });
    }
  }

  formatRow(field) {
    const {record} = this.state;
    if (record.table === 'closet' && field === 'BUILDING_ID') {
      const buildingList = makeSelectList(record.buildings, record.buildingRefId, record.buildingRefName);
      return (
          <FormControl id={field} name={record.buildingRefId} as="select" onChange={this.onChange}>
            {buildingList}
          </FormControl>
      );
    } else {
      return <FormControl id={field} name={field} onChange={this.onChange}/>;
    }
  }

  makeFieldRows() {
    const {record} = this.state;
    return record.inputFields.map(fieldName => {
      const format = this.formatRow(fieldName);
      return (
          <Form.Row key={fieldName}>
            <Form.Label htmlFor={fieldName} srOnly>{fieldName}</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend as={Col} sm={6}>
                <InputGroup.Text as={Col}>{fieldName}</InputGroup.Text>
              </InputGroup.Prepend>
              {format}
            </InputGroup>
          </Form.Row>
      );
    });
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const {record} = this.state;
    record.updateValue(name, value);
    this.setState({record});
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {showSuccess, showError} = this.props;
    const {record} = this.state;
    if (record.isUnique && record.isValid) {
      const data = record.newRecordQueryData(this.context.username);
      db('reference', 'POST', data, showError)
          .then(res => {
            const msg = `Added ${JSON.stringify(data)} to table: ${record.table}. Received ${JSON.stringify(
                res)} from api.`;
            showSuccess(msg);
            console.log(msg);
            this.props.history.push({
              pathname: '/reference',
              state: {
                table: record.table,
                data: {},
              },
            });
          });
    } else {
      const errMsgs = [];
      if (record.unUniqueMessage) errMsgs.push(`Uniqueness failure: ${record.unUniqueMessage}`);
      if (record.invalidMessage) errMsgs.push(`Validity failure: ${record.invalidMessage}`);

      showError(errMsgs);
    }
  }

  render() {
    const fieldRows = this.makeFieldRows();
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <Col sm={7}>
            <h1>Add a new {this.state.record.table}</h1>
            <Form onSubmit={this.handleSubmit}>
              {fieldRows}
              <Form.Row>
                <Button variant="secondary" className="ml-3 mr-2" onClick={() => this.props.history.push(
                    {pathname: '/reference', state: {table: this.state.record.table, data: {}}})}>
                  Cancel
                </Button>
                <Button type="submit" className="ml-2">Submit Update</Button>
              </Form.Row>
            </Form>
          </Col>
        </div>
    );
  }
}
ReferenceAdd.contextType = UserContext;