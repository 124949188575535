import React from 'react';
import './Contents.css';
import {Col, Form, InputGroup, FormControl, Button} from 'react-bootstrap';
import {UserContext} from './UserContext.js';
import User from './User.js';

export default class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        USERNAME: '',
        ROLE_LEVEL: 0,
        SET_MANUALLY: 'Y',
      },
    };
    this.makeFieldRows = this.makeFieldRows.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // TODO: add validation to input fields
  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('user-management')) {
        this.props.history.push({
          pathname: '/',
        });
      }}, 100);
  }

  formatRow(field) {
    const {user} = this.state;
    switch (field) {
      case 'ROLE_LEVEL':
        return (<FormControl id={field} name={field} value={user[field]} as="select" onChange={this.onChange}
                             custom>
          <option key="0" value="0">No Access (0)</option>
          <option key="1" value="1">Reports (1)</option>
          <option key="2" value="2">Advanced Reports (2)</option>
          <option key="3" value="3">Modify and Admin (3)</option>
          <option key="4" value="4">User Management (4)</option>
        </FormControl>);
        // break;
      case 'SET_MANUALLY':
        return (<FormControl id={field} name={field} value={user[field]} as="select" onChange={this.onChange}
                             custom>
          <option key="Y" value="Y">Y</option>
          <option key="N" value="N">N</option>
        </FormControl>);
        // break;
      default:
        return <FormControl id={field} name={field} value={user[field]} onChange={this.onChange}/>;
    }
  }

  makeFieldRows() {
    const {user} = this.state;
    return Object.keys(user).map(fieldName => {
      const format = this.formatRow(fieldName);
      return (
          <Form.Row key={fieldName}>
            <Form.Label htmlFor={fieldName} srOnly>{fieldName}</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Prepend as={Col} sm={6}>
                <InputGroup.Text as={Col}>{fieldName}</InputGroup.Text>
              </InputGroup.Prepend>
              {format}
            </InputGroup>
          </Form.Row>
      );
    });
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const {user} = this.state;
    user[name] = value;
    this.setState({user});
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {showSuccess} = this.props;
    const {USERNAME, ROLE_LEVEL, SET_MANUALLY} = this.state.user;
    const admin_username = this.context.username;
    const {showError} = this.props;
    const res = await User.addNewUser(USERNAME, parseInt(ROLE_LEVEL), SET_MANUALLY, admin_username, showError);
    if (res) {
      const msg = `Added ${JSON.stringify(this.state.user)}. Received ${JSON.stringify(res)} from api.`;
      if (res.status === 200) showSuccess(msg);
      console.log(msg);
    }
    this.props.history.push({
      pathname: '/users',
      state: {
        data: {},
      },
    });
  }

  render() {
    const fieldRows = this.makeFieldRows();
    return (
        <div style={{display: this.context.showNav('user-management') ? 'block' : 'none'}}>
          <Col sm={7}>
            <h1>Add a new {this.state.table}</h1>
            <Form onSubmit={this.handleSubmit}>
              {fieldRows}
              <Form.Row>
                <Button variant="secondary" className="ml-3 mr-2" onClick={() => this.props.history.push(
                    {pathname: '/reference', state: {table: this.state.record.table, data: {}}})}>
                  Cancel
                </Button>
                <Button type="submit" className="ml-2">Submit Update</Button>
              </Form.Row>
            </Form>
          </Col>
        </div>
    );
  }
}
UserAdd.contextType = UserContext;