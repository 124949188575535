import db from './db.js';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Contents.css';
import {prepareQuery, formValues, filterClosets, parseFormValues, processPassedQuery} from './uiSharedResources.js';
import {UserContext} from './UserContext.js';

async function runQuery(query, showError) {
  return await db('open-query', 'GET', query, showError);
}

export default class Query extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: null,
      allClosets: null,
      closets: null,
      networks: null,
      portSpeeds: null,
      duplexes: null,
      statuses: null,
      query: {
        building_id: '',
        closet_id: '',
        station_quad: '',
        station_port: '',
        port_speed_id: '',
        port_duplex_id: '',
        network_id: '',
        notes: '',
        port_status: '',
        port_status_date: '',
        port_status_ticket: '',
      },
    };

    this.updateClosets = this.updateClosets.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('reports')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    const {showError} = this.props;
    formValues(showError)
        .then(v => {
          const {buildings, allClosets, networks, portSpeeds, duplexes, statuses} = parseFormValues(v);
          this.setState({buildings, allClosets, networks, portSpeeds, duplexes, statuses});
        })
        .then(() => {
          if (this.props.location.state) {
            const currentQuery = this.state.query;
            const passedQuery = this.props.location.state.query;
            const query = processPassedQuery(currentQuery, passedQuery);
            this.setState({query});
            return passedQuery.building_id;
          } else {
            return undefined;
          }
        })
        .then(building_id => {
          if (building_id) {
            this.updateClosets(building_id);
          }
        });
  }

  updateClosets(building_id) {
    const closets = filterClosets(this.state.allClosets, building_id);
    this.setState({closets});
  }

  resetForm() {
    const query = this.state.query;
    Object.keys(query).forEach(key => {
      query[key] = '';
      if (key === 'port_disable') query[key] = false;
    });
    this.setState({query});
  }

  onChange(event, naturalValue) {
    const {name, value: textValue, type, checked} = event.target;
    const value = type === 'checkbox' ? checked : naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'building_id') this.updateClosets(value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showError} = this.props;
    const fullQuery = this.state.query;
    const query = prepareQuery(fullQuery);
    runQuery(query, showError)
        .then(res => {
          this.props.history.push({
            pathname: '/results',
            state: {
              data: res,
              query: query,
              startPage: '/query',
            },
          });
        });
  }

  render() {
    return (
        <div style={{display: this.context.showNav('reports') ? 'block' : 'none'}}>
          <div className="ContentTitle">
            Open Query
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="formBldName">
                <Form.Label>Building Name</Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['building_id']}
                              name="building_id" custom>
                  {this.state.buildings}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="formClosetNum">
                <Form.Label>Closet</Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['closet_id']}
                              name="closet_id" custom>
                  {this.state.closets}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="quadLabel">
                <Form.Label>Quad Label</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.query['station_quad']} name="station_quad"/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="quadPort">
                <Form.Label>Quad Port</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.query['station_port']} name="station_port"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="portSpeed">
                <Form.Label>Port Speed</Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['port_speed_id']}
                              name="port_speed_id" custom>
                  {this.state.portSpeeds}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="portDuplex">
                <Form.Label>Port Duplex</Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['port_duplex_id']}
                              name="port_duplex_id" custom>
                  {this.state.duplexes}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="networkName">
                <Form.Label>Network Name</Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['network_id']}
                              name="network_id" custom>
                  {this.state.networks}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group as={Row} className="align-items-center" controlId="notes">
              <Col md={1} sm={2} xs={3}>
                <Form.Label>Notes</Form.Label>
              </Col>
              <Col md={5} sm={7} xs={12}>
                <Form.Control onChange={this.onChange} value={this.state.query['notes']} name="notes"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="portStatus">
              <Col xl={1} md={2} sm={3} xs={4}>
                <Form.Label>Port Status</Form.Label>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query['port_status_id']}
                              name="port_status_id" custom>
                  {this.state.statuses}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="dateDisabled">
                <Form.Label>Date Disabled</Form.Label>
                <Form.Control placeholder="yyyy-mm-dd" className="text-center" onChange={this.onChange}
                              value={this.state.query['port_disable_date']} name="port_disable_date"/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId="troubleTicket">
                <Form.Label>Trouble Ticket</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.query['port_disable_ticket']}
                              name="port_disable_ticket"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col>
                <Button onClick={this.resetForm}
                        variant="secondary"
                        size="sm"
                >
                  Reset Form
                </Button>
                <Button
                    className={'ml-3'}
                    variant="primary"
                    size="sm"
                    type="submit"
                >
                  Execute Query
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
Query.contextType = UserContext;