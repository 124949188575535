import React from 'react';
import db from './db.js';

function validIP(value) {
  return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);
}

async function formValues(showError, table) {
  const data = table ? {table} : {};
  const vals = await db('form-values', 'GET', data, showError);
  return vals != null ? vals.results ? vals.results : vals : null;
}

async function patchPanels(closet_id, showError) {
  const vals = await db('patch-panels', 'GET', {closet_id}, showError);
  return vals != null ? vals.rows : null;
}

// TODO: update to accept the building name instead of event
function filterClosets(allClosets, event) {
  if (allClosets !== null && Object.keys(allClosets).length > 0 && event !== null && event !== '') {
    const id = typeof event === 'string' ? event : event.target.value;
    const someClosets = allClosets.filter(c => c.BUILDING_ID === parseInt(id));
    return makeSelectList(someClosets, 'CLOSET_ID', 'CLOSET_NUMBER');
  } else {
    return null;
  }
}

function makeSelectList(menuItems, id, name) {
  if (!menuItems || Object.keys(menuItems).length === 0) return null;
  const parsedList = menuItems.map((item) => (
      <option key={item[id]} value={item[id]}>{item[name]}</option>
  ));
  return [<option key="" value=""/>].concat(parsedList);
}

function parseFormValues(data) {
  let buildings = null;
  let allClosets = null;
  let connections = null;
  let networks = null;
  let portSpeeds = null;
  let duplexes = null;
  let statuses = null;
  if (data != null) {
    buildings = makeSelectList(data.buildings.rows, 'BUILDING_ID', 'BUILDING_NAME');
    allClosets = data.closets.rows;
    connections = makeSelectList(data.connections.rows, 'CONN_TYPE_ID', 'CONN_TYPE_NAME');
    networks = makeSelectList(data.networks.rows, 'NETWORK_ID', 'NETWORK_NAME');
    portSpeeds = makeSelectList(data.portSpeeds.rows, 'PORT_SPEED_ID', 'PORT_SPEED');
    duplexes = makeSelectList(data.duplexes.rows, 'PORT_DUPLEX_ID', 'PORT_DUPLEX');
    statuses = makeSelectList(data.statuses.rows, 'PORT_STATUS_ID', 'PORT_STATUS');
  }
  return {buildings, allClosets, connections, networks, portSpeeds, duplexes, statuses};
}

// TODO: Remove port_disable if statement when all pages have been updated to use port_status
function processPassedQuery(currentQuery, passedQuery) {
  Object.keys(passedQuery).forEach(key => {
    currentQuery[key] = passedQuery[key];
    if (key === 'port_disable') currentQuery[key] = passedQuery[key] === 'Y';
  });
  return currentQuery;
}

// TODO: Remove port_disable statement when all pages have been updated to use port_status
function prepareQuery(fullQuery) {
  const query = {};
  Object.keys(fullQuery).forEach(key => {
    const value = fullQuery[key];
    if (value !== '' && value !== '0') query[key] = value;
  });
  // query.port_disable = query.port_disable ? 'Y' : 'N';
  return query;
}

function formatDateField(rows) {
  if (rows !== undefined && rows.length > 0) {
    rows.forEach(row => {
      if (row.LAST_MODIFIED_DATE != null) {
        row.LAST_MODIFIED_DATE = row.LAST_MODIFIED_DATE.substring(0, 10);
      }
      if (row.PORT_STATUS_DATE != null) {
        row.PORT_STATUS_DATE = row.PORT_STATUS_DATE.substring(0, 10);
      }
    });
  }
  if (rows && rows.LAST_MODIFIED_DATE) {
    rows.LAST_MODIFIED_DATE = rows.LAST_MODIFIED_DATE.substring(0, 10);
  }
  if (rows && rows.PORT_STATUS_DATE) {
    rows.PORT_STATUS_DATE = rows.PORT_STATUS_DATE.substring(0, 10);
  }
  return rows;
}

export {
  prepareQuery,
  makeSelectList,
  formValues,
  filterClosets,
  parseFormValues,
  processPassedQuery,
  patchPanels,
  validIP,
  formatDateField,
};