import React from 'react';

import './Contents.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

export default function ResultsTable({headers, rows}) {
  if (headers === undefined || rows === undefined) {
    return (
        <div/>
    );
  } else {
    const myColumns = headers.map(cell => {
      return {Header: cell.name.replaceAll('_', ' '), accessor: cell.name, minWidth: 150};
    });

    return (
        <div>
          <ReactTable
              data={rows}
              columns={myColumns}
              defaultPageSize={50}
              style={{height: '500px'}}
              className="-striped -highlight"
          />
        </div>
    );
  }
  // }
}