import React from 'react';
import {Switch, Route} from 'react-router-dom';
import routes from './routes.js';
import withToast from './withToast';

function RouteWithToast({path, component: Component, ...rest}) {
  return (
      <Route path={path}
             key={path}
             render={routeProps => (
                 <Component {...routeProps} {...rest} />
             )}
      />
  );
}

function Contents(props) {
  return (
      <Switch>
        {routes.map(attrs => <RouteWithToast {...attrs} {...props} key={attrs.path}/>)}
      </Switch>
  );
}

const ContentsWithToast = withToast(Contents);
export default ContentsWithToast;

