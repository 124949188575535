import React from 'react';
import {Nav, NavDropdown, Container, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Image} from 'react-bootstrap';
import './App.css';
import logo from './logo_nu.jpg';
import {UserContext} from './UserContext.js';
import User from './User.js';
import Contents from './Contents.jsx';

function Head({logOut}) {
  return (
      <Row className={'App-header flex-nowrap'}>
        <Col xs={3}>
          <Nav>
            <Nav.Link as={Link} to="/">
              <Image src={logo} alt="NU Logo" fluid/>
            </Nav.Link>
          </Nav>
        </Col>

        <Col lg={7} md={6} xs={5}>
          <div className="App-title">Cutsheet Database</div>
        </Col>
        <Col lg={2} md={3} xs={4} >
          <Nav>
            <UserContext.Consumer>
              {user =>
                  <NavDropdown title={user.name} id="nav-dropdown">
                    <NavDropdown.Item as={Link} to="/logout" onClick={() => logOut()}>Logout</NavDropdown.Item>
                  </NavDropdown>
              }
            </UserContext.Consumer>
          </Nav>
        </Col>
      </Row>
  );
}

function NavBar() {
  return (
      <UserContext.Consumer>
        {user =>
            <Nav style={{display: user.showNav('reports') ? 'block' : 'none'}} className="flex-column NavBar">
              {/*<div style={{display: [1, 2, 3, 4].includes(user.roleLevel) ? 'block' : 'none'}}>*/}
              <div>
                <div className="App-nav-section">Reports</div>
                <Nav.Link className="App-nav-item" as={Link} to="/query">Open Query</Nav.Link>
              </div>
              <div style={{display: user.showNav('advanced-reports') ? 'block' : 'none'}}>
                <div className="App-nav-section">Advanced Reports</div>
                <Nav.Link className="App-nav-item" as={Link} to="/queryfull">Open Query Full</Nav.Link>
                <Nav.Link className="App-nav-item" as={Link} to="/cutsheet">Closet Cutsheet</Nav.Link>
                <Nav.Link className="App-nav-item" as={Link} to="/cutsheetnotes">Closet Cutsheet Notes</Nav.Link>
              </div>
              <div style={{display: user.showNav('modify') ? 'block' : 'none'}}>
                <div className="App-nav-section">Modify</div>
                <Nav.Link className="App-nav-item" as={Link} to="/panel">by Panel Port</Nav.Link>
                <Nav.Link className="App-nav-item" as={Link} to="/switch">by Switch Port</Nav.Link>
                <Nav.Link className="App-nav-item" as={Link} to="/upload">by File Import</Nav.Link>
                <Nav.Link className="App-nav-item" as={Link} to="reference">Reference Tables</Nav.Link>
              </div>
              <div style={{display: user.showNav('administrator') ? 'block' : 'none'}}>
                <div className="App-nav-section">Administrator</div>
                <Nav.Link className="App-nav-item" as={Link} to="/addpanel">Add New Panel</Nav.Link>
              </div>
              <div style={{display: user.showNav('user-management') ? 'block' : 'none'}}>
                <div className={'App-nav-section'}>User Management</div>
                {/*<Nav.Link className={'App-nav-item'} as={Link} to={'/adduser'}>Add New User</Nav.Link>*/}
                <Nav.Link className={'App-nav-item'} as={Link} to={'/users'}>Edit Users</Nav.Link>
              </div>
            </Nav>
        }
      </UserContext.Consumer>
  );
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    const username = this.props.username || 'a.smith';
    const name = this.props.name || 'Adam Level 4';
    this.state = {
      user: new User(username, name),
    };
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    // TODO: udpate this to pull from SSO
    const {user} = this.state;
    user.loadRole().then(() => {
      this.setState({user});
    });
  }

  logOut() {
    const {user} = this.state;
    console.log(`About to log out: ${this.state.user.username}`);

    user.logout();
    this.setState({user});

    console.log(`Current status: ${JSON.stringify(this.state.user)}`);
  }

  render() {
    return (
        <div className="App">
          <Container fluid>
            <UserContext.Provider value={this.state.user}>
              <Head logOut={this.logOut}><title>Cutsheet Database</title></Head>
              <Row className={'flex-nowrap'}>
                <Col xl={2} lg={3} md={3} sm={4} xs={5}>
                  <NavBar/>
                </Col>
                <Col xl={10} lg={9} md={9} sm={8} xs={7}>
                  <Contents/>
                </Col>
              </Row>
              {/* <Footer /> */}
            </UserContext.Provider>
          </Container>
        </div>
    );
  }
}