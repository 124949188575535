import React from 'react';
import {UserContext} from './UserContext.js';

export default class MainScreen extends React.Component {
  render() {
    return (
        <div style={{display: this.context.roleLevel === 0 ? 'block' : 'none'}}>
          <h1>Unauthorized</h1>
          <p>You do not have authorization to access to the Cutsheet Database.</p>
          <p>If you believe that you should, please contact SOMEONE at <a href={'mailto:'}>someone@somewhere.com</a>.</p>
        </div>
    );
  }
}
MainScreen.contextType = UserContext;