import db from './db.js';

async function checkSecurity(query) {
  return await db('auth', 'GET', query);
}

function userIsValid(username, role_level, set_manually, admin_username) {
  const validValues = {
    user_name: (uName) => /^[^\s]{1,20}$/.test(uName),
    access_level: (level) => [0, 1, 2, 3, 4].includes(parseInt(level)),
    set_manually: (manual) => /^[NY]$/.test(manual),
    admin_name: (aName) => /^[^\s]{1,20}$/.test(aName),
  };

  return validValues.user_name(username) && validValues.access_level(role_level) &&
      validValues.set_manually(set_manually) && validValues.admin_name(admin_username);
}

export default class User {
  constructor(username, name) {
    this._username = username || '';
    this._name = name || '';
    this._loggedIn = false;
    this._roleLevel = 0;
  }

  get username() {
    return this._username;
  }

  get name() {
    return this._name;
  }

  get roleLevel() {
    return this._roleLevel;
  }

  get isLoggedIn() {
    return this._loggedIn;
  }

  showNav(nav) {
    let show = false;
    switch (nav) {
      case 'reports':
        show = [1, 2, 3, 4].includes(this._roleLevel);
        break;
      case 'advanced-reports':
        show = [2, 3, 4].includes(this._roleLevel);
        break;
      case 'modify':
      case 'administrator':
        show = [3, 4].includes(this._roleLevel);
        break;
      case 'user-management':
        show = [4].includes(this._roleLevel);
        break;
      default:
    }
    return show;
  }

  static async addNewUser(username, role_level, set_manually, admin_username, showError) {
    if (userIsValid(username, role_level, set_manually, admin_username)) {
      const query = {username, role_level, set_manually, admin_username};
      return await db('auth', 'POST', query, showError);
    } else {
      showError('New User has invalid values.')
    }
  }

  async loadRole() {
    const res = await checkSecurity({username: this._username});

    if (res && res.rows && res.rows.length > 0) {
      this._loggedIn = true;
      this._roleLevel = res.rows[0]['ROLE_LEVEL'];
    } else {
      const msg = res ? res.message : 'No response from API.'
      console.log(`App.checkSecurity Error: ${msg}`);
      if (res.status === 404) {
        await User.addNewUser(this._username, 0, 'N', 'CSDB_AUTO_GEN');
      }
    }
  }

  logout() {
    this._username = '';
    this._name = '';
    this._loggedIn = false;
    this._roleLevel = 0;
  }
}