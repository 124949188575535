import React from 'react';
import {Form, Button} from 'react-bootstrap';
import db from './db.js';
import {UserContext} from './UserContext';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';

function CleanupRows(idName, rows) {
  rows.forEach(row => {
    const date = new Date(row.LAST_MODIFIED_DATE);
    row.LAST_MODIFIED_DATE = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

    const thisRow = {...row};
    row.EDIT = <Button
        as={Link}
        className="ExecuteQuery"
        variant="outline-dark"
        size="sm"
        key={row[idName]}
        to={{pathname: '/user/edit', state: {record: thisRow}}}
    >Edit</Button>;
  });
}

function UserTable({headers, rows}) {
  const hiddenCols = {'USER_ROLE_ID': true};
  if (headers === undefined || rows === undefined) {
    return (
        <div/>
    );
  } else {
    const myColumns = headers.map(cell => {
      return {Header: cell.name, accessor: cell.name, show: !hiddenCols[cell.name]};
    });

    const idName = headers[0].name;

    myColumns.push({Header: 'Edit', accessor: 'EDIT'});

    CleanupRows(idName, rows);

    return (
        <ReactTable
            data={rows}
            columns={myColumns}
            defaultPageSize={50}
            style={{height: '500px'}}
            className="-striped -highlight"
        />
    );
  }
}

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state ? this.props.location.state.data : {},
    };
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const {showError} = this.props;
    db('users', 'GET', {}, showError)
        .then(res => {
          if (res === null) {
            const msg = 'Could not reach the database';
            console.log(msg);
            showError(msg);
          } else {
            this.setState({data: res});
          }
        });

  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('user-management')) {
        this.props.history.push({
          pathname: '/',
        });
      }}, 100);

    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (!this.props.toastVisible && prevProps.toastVisible) {
      this.loadData();
    }
  }

  render() {
    const {metaData, rows} = this.state.data;
    return (
        <div style={{display: this.context.showNav('user-management') ? 'block' : 'none'}}>
          <h1>User Maintenance</h1>
          <Form inline>
            <Button variant="primary"  onClick={() => {
              this.props.history.push({
                pathname: '/user/add',
              });
            }}>Add new user</Button>
          </Form>
          <UserTable headers={metaData} rows={rows}/>
        </div>
    );
  }
}
Users.contextType = UserContext;
