import React from 'react';
import './Contents.css';
import {Col, Form, Row, FormControl, Button} from 'react-bootstrap';
import db from './db.js';
import {formValues} from './uiSharedResources.js';
import {UserContext} from './UserContext.js';

export default class ReferenceEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: this.props.location.state.record,
    };
    this.makeFieldRows = this.makeFieldRows.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    const table = this.state.record.table;
    const {showError} = this.props;
    formValues(showError, table)
        .then(res => {
          const {record} = this.state;
          record.addValidation(res);
          this.setState({record});
        });
    if (table === 'closet') {
      formValues(showError, 'building')
          .then(res => {
            const {record} = this.state;
            record.addBuildingList(res);
            this.setState({record});
          });
    }
  }

  formatRow(field) {
    const {record} = this.state;
    if (!record.editableFieldsForTable.includes(field)) {
      return <FormControl id={field} name={field} value={record.value(field)} readOnly plaintext/>;
    } else if (field === 'ACTIVE') {
      return (<FormControl id={field} name={field} value={record.value(field)} as="select" onChange={this.onChange}
                           custom>
        <option key="Y" value="Y">Y</option>
        <option key="N" value="N">N</option>
      </FormControl>);
    } else {
      return <FormControl id={field} name={field} value={record.value(field)} onChange={this.onChange}/>;
    }
  }

  makeFieldRows() {
    const {record} = this.state;
    return record.fieldKeys.filter(field => !record.hiddenColumns[field]).map(field => {
      const format = this.formatRow(field);
      return (
          <Form.Group as={Row} key={field} className="align-items-center">
            <Col xl={2} lg={3} md={4} sm={5} xs={12}>
              <Form.Label>{field}</Form.Label>
            </Col>
            <Col xl={3} lg={4} md={5} sm={7} xs={12}>
              {format}
            </Col>
          </Form.Group>
      );
    });
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const {showWarning} = this.props;
    const {record} = this.state;

    if (record.isCascadeDeactivation(name, value)) {
      const acceptDeactivate = (response) => {
        if (response === 'accept') {
          record.updateValue(name, value);
          this.setState({record});
        }
      };
      const msg = `Disabling this record from table "${record.table}" will also disable all related records in the ${record.table ===
      'building' ? 'Closet and Cutsheet tables' : 'Cutsheet table'}. Are you sure?`;
      showWarning(msg, acceptDeactivate);
    } else {
      record.updateValue(name, value);
      this.setState({record});
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showSuccess, showError} = this.props;
    const {record} = this.state;
    if (record.isValid) {
      const query = record.updateQueryData(this.context.username);
      db('reference', 'PUT', query, showError)
          .then(res => {
            if (res) {
              const msg = `Updated table: ${record.table} id: ${record.id} with ${JSON.stringify(
                  record.updates)}. Received ${res} from api.`;
              showSuccess(msg);
              console.log(msg);
            }
            this.props.history.push({
              pathname: '/reference',
              state: {
                table: record.table,
                data: {},
              },
            });
          });
    } else {
      const msg = record.invalidMessage;
      showError(msg);
      console.log(msg);
    }
  }

  render() {
    const fieldRows = this.makeFieldRows();
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <h1>Reference Edit</h1>
          <Form onSubmit={this.handleSubmit}>
            {fieldRows}
            <Form.Row>
              <Button variant="secondary" onClick={() => this.props.history
                  .push({pathname: '/reference', state: {table: this.state.record.table, data: {}}})}>
                Cancel
              </Button>
              <Button type="submit" className="ml-3">Submit Update</Button>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
ReferenceEdit.contextType = UserContext;
