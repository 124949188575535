import db from './db.js';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Contents.css';
import {prepareQuery, filterClosets, formValues, parseFormValues, processPassedQuery} from './uiSharedResources.js';
import {UserContext} from './UserContext';

async function runQuery(query, showError) {
  return await db('open-query-full', 'GET', query, showError);
}

export default class QueryFull extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: null,
      allClosets: null,
      closets: null,
      connections: null,
      networks: null,
      portSpeeds: null,
      duplexes: null,
      statuses: null,
      query: {
        cutsheet_id: '',
        building_id: '',
        closet_number: '',
        patch_panel: '',
        patch_panel_port: '',
        station_quad: '',
        station_port: '',
        system_name: '',
        ip_address: '',
        stack_switch: '',
        switch_port: '',
        port_speed_id: '',
        port_duplex_id: '',
        vlan_number: '',
        network_id: '',
        conn_type_id: '',
        notes: '',
        port_status_id: '',
        port_status_date: '',
        port_status_ticket: '',
        sort_order: '',
      },
    };

    this.updateClosets = this.updateClosets.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('advanced-reports')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    const {showError} = this.props;
    formValues(showError)
        .then(v => {
          const {buildings, allClosets, connections, networks, portSpeeds, duplexes, statuses} = parseFormValues(v);
          this.setState({buildings, allClosets, connections, networks, portSpeeds, duplexes, statuses});
        })
        .then(() => {
          if (this.props.location.state) {
            const currentQuery = this.state.query;
            const passedQuery = this.props.location.state.query;
            const query = processPassedQuery(currentQuery, passedQuery);
            this.setState({query});
            return passedQuery.building_id;
          } else {
            return undefined;
          }
        })
        .then(building_id => {
          if (building_id) {
            this.updateClosets(building_id);
          }
        });
  }

  updateClosets(building_id) {
    const closets = filterClosets(this.state.allClosets, building_id);
    this.setState({closets});
  }

  // TODO: update to remove port_disable if statement when ready
  resetForm() {
    const query = this.state.query;
    Object.keys(query).forEach(key => {
      query[key] = '';
      if (key === 'port_disable') query[key] = false;
    });
    this.setState({query});
  }

  onChange(event, naturalValue) {
    const {name, value: textValue, type, checked} = event.target;
    const value = type === 'checkbox' ? checked : naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'building_id') this.updateClosets(value);
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showError} = this.props;
    const fullQuery = this.state.query;
    const query = prepareQuery(fullQuery);
    runQuery(query, showError).then(res => {
      this.props.history.push({
        pathname: '/results',
        state: {
          data: res,
          query: query,
          startPage: '/queryfull',
        },
      });
    });
  }

  render() {
    return (
        <div style={{display: this.context.showNav('advanced-reports') ? 'block' : 'none'}}>
          <div className="ContentTitle">
            Open Query Full
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId={'formRecordId'}>
                <Form.Label>Record ID <span className={'em'}> (cutsheet_id)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} name={'cutsheet_id'}
                              value={this.state.query.cutsheet_id}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="formBldName">
                <Form.Label>Building Name<span className={'em'}> (building_name)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.building_id}
                              name="building_id" custom>
                  {this.state.buildings}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="formClosetNum">
                <Form.Label>Closet Num<span className={'em'}> (closet_number)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.closet_id} name="closet_id"
                              custom>
                  {this.state.closets}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="panelLabel">
                <Form.Label>Panel Label<span className={'em'}> (patch_panel)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.patch_panel}
                              name="patch_panel"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="pPanelPort">
                <Form.Label>Patch Panel Port<span className={'em'}> (patch_panel_port)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.patch_panel_port}
                              name="patch_panel_port"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="stationQuad">
                <Form.Label>Quad Label<span className={'em'}> (station_quad)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.station_quad}
                              name="station_quad"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="stationPort">
                <Form.Label>Quad Port<br/><span className={'em'}> (station_port)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.station_port}
                              name="station_port"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="systemName">
                <Form.Label>System Name<span className={'em'}> (system_name)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.system_name}
                              name="system_name"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="switchIPAddress">
                <Form.Label>Switch IP Address<br/><span className={'em'}> (ip_address)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.ip_address}
                              name="ip_address"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="stackSwitch">
                <Form.Label>Switch Stack<br/><span className={'em'}> (stack_switch)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.stack_switch}
                              name="stack_switch"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={6} xs={12} controlId="switchPort">
                <Form.Label>Switch Port<span className={'em'}> (switch_port)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.switch_port}
                              name="switch_port"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={4} md={5} sm={6} xs={12} controlId="portSpeed">
                <Form.Label>Port Speed<span className={'em'}> (port_speed)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.port_speed_id}
                              name="port_speed_id" custom>
                  {this.state.portSpeeds}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={4} md={5} sm={6} xs={12}  controlId="portDuplex">
                <Form.Label>Port Duplex<span className={'em'}> (port_duplex)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.port_duplex_id}
                              name="port_duplex_id" custom>
                  {this.state.duplexes}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={4} md={5} sm={6} xs={12}  controlId="vlanNumber">
                <Form.Label>VLAN Number<span className={'em'}> (vlan_number)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.vlan_number}
                              name="vlan_number"/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={4} md={5} sm={6} xs={12}  controlId="networkName">
                <Form.Label>Network Name<span className={'em'}> (network_name)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.network_id} name="network_id"
                              custom>
                  {this.state.networks}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={4} md={5} sm={6} xs={12}  controlId="connectionType">
                <Form.Label>Connection Type<span className={'em'}> (conn_type_name)</span></Form.Label>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.conn_type_id}
                              name="conn_type_id" custom>
                  {this.state.connections}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group as={Row}  className="align-items-center" controlId="notes">
              <Col md={1} sm={2} xs={3}>
                <Form.Label>Notes</Form.Label>
              </Col>
              <Col>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.notes} name="notes"/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="portStatus">
              <Col xl={2} md={3}  xs={5}>
                <Form.Label>Port Status<span className={'em'}> (port_status)</span></Form.Label>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                <Form.Control as="select" onChange={this.onChange} value={this.state.query.port_status_id}
                              name="port_status_id" custom>
                  {this.state.statuses}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={'auto'} xs={12} controlId="dateDisabled">
                <Form.Label>Date Modified<span className={'em'}> (port_status_date)</span></Form.Label>
                <Form.Control type={'text'} placeholder="yyyy-mm-dd" className="text-center" onChange={this.onChange}
                              value={this.state.query.port_status_date} name="port_status_date"/>
              </Form.Group>
              <Form.Group as={Col} xl={3} lg={4} md={5} sm={'auto'} xs={12} controlId="troubleTicket">
                <Form.Label>Trouble Ticket<span className={'em'}> (port_status_ticket)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.port_status_ticket}
                              name="port_status_ticket"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={9} xs={12} controlId="sortOrder">
                <Form.Label>Sort Order Comma Separated<span className={'em'}> (Default order is building_name, closet_number, patch_panel, cutsheet_id)</span></Form.Label>
                <Form.Control type={'text'} onChange={this.onChange} value={this.state.query.sort_order}
                              name="sort_order"/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col>
                <Button onClick={this.resetForm}
                        variant="secondary"
                        size="sm"
                >
                  Reset Form
                </Button>
                <Button
                    className="ml-3"
                    variant="primary"
                    size="sm"
                    type="submit"
                >
                  Execute Query
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
QueryFull.contextType = UserContext;