const nameIsValid = (name, table, nameField) => {
  if (name !== null) {
    for (const row of table) {
      if (row[nameField] === name) return true;
    }
  }
  return false;
};

// TODO: Update CUTSHEET_ID validation to check if ID is valid
const validationDetails = {
  CUTSHEET_ID: {
    type: 'number',
    isValid: (id) => (id !== null) && /^[0-9]+$/.test(id),
    invalidMsg: (id) => `cannot be empty or contain a non-numeric character. Current value: '${id}'.`,
  },
  LAST_MODIFIED_DATE: {
    type: 'ignore',
  },
  BUILDING_NAME: {
    type: 'ignore',
    // type: 'lookup',
    // lookupTable: 'buildings',
    // isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    // invalidMsg: (name) => `cannot be empty and must match an existing building. If needed, create new building first. Current Value: '${name}'`,
  },
  CLOSET_NUMBER: {
    type: 'ignore',
    // type: 'lookup',
    // lookupTable: 'closets',
    // isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    // invalidMsg: (name) => `cannot be empty and must match an existing closet. If needed, create new closet first. Current Value: '${name}'`,
  },
  PATCH_PANEL: {
    type: 'ignore',
    // type: 'string',
    // isValid: (value) => (value !== null) && /^\S{1,25}$/.test(value),
    // invalidMsg: (value) => `cannot be empty, contain white space, or exceed 25 characters. Current value: '${value}'.`,
  },
  PATCH_PANEL_PORT: {
    type: 'ignore',
    // type: 'string',
    // isValid: (value) => (value !== null) && /^\d{1,10}$/.test(value),
    // invalidMsg: (value) => `must be a number with a length between 1 and 10. Current value: '${value}'.`,
  },
  STATION_QUAD: {
    type: 'string',
    isValid: (value) => /^\S{0,20}$/.test(value),
    invalidMsg: (value) => `cannot contain white space or exceed 20 characters. Current value: '${value}'.`,
  },
  STATION_PORT: {
    type: 'string',
    isValid: (value) => /^.{0,10}$/.test(value),
    invalidMsg: (value) => `cannot exceed 10 characters. Current length: ${value.toString().length}.`,
  },
  SYSTEM_NAME: {
    type: 'string',
    isValid: (value) => /^.{0,40}$/.test(value),
    invalidMsg: (value) => `cannot exceed 40 characters. Current length: ${value.toString().length}.`,
  },
  IP_ADDRESS: {
    type: 'string',
    isValid: (value) => value === null ||
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value),
    invalidMsg: (value) => `is invalid. Current value: '${value}'.`,
  },
  STACK_SWITCH: {
    type: 'string',
    isValid: (value) => /^.{0,10}$/.test(value),
    invalidMsg: (value) => `cannot exceed 10 characters. Current length: ${value.toString().length}.`,
  },
  SWITCH_PORT: {
    type: 'string',
    isValid: (value) => /^.{0,10}$/.test(value),
    invalidMsg: (value) => `cannot exceed 10 characters. Current length: ${value.toString().length}.`,
  },
  PORT_SPEED_ID: {
    type: 'ignore',
  },
  PORT_SPEED: {
    type: 'lookup',
    lookupTable: 'portSpeeds',
    isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    invalidMsg: (name) => `cannot be empty and must match an existing port speed. If needed, create new port speed first. Current Value: '${name}'`,
  },
  PORT_DUPLEX_ID: {
    type: 'ignore',
  },
  PORT_DUPLEX: {
    type: 'lookup',
    lookupTable: 'duplexes',
    isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    invalidMsg: (name) => `cannot be empty and must match an existing duplex. If needed, create new duplex first. Current Value: '${name}'`,
  },
  VLAN_NUMBER: {
    type: 'string',
    isValid: (value) => /^.{0,100}$/.test(value),
    invalidMsg: (value) => `cannot exceed 100 characters. Current length: ${value.toString().length}.`,
  },
  NETWORK_ID: {
    type: 'ignore',
  },
  NETWORK_NAME: {
    type: 'lookup',
    lookupTable: 'networks',
    isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    invalidMsg: (name) => `cannot be empty and must match an existing network. If needed, create new network first. Current Value: '${name}'`,
  },
  CONN_TYPE_ID: {
    type: 'ignore',
  },
  CONN_TYPE_NAME: {
    type: 'lookup',
    lookupTable: 'connections',
    isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    invalidMsg: (name) => `cannot be empty and must match an existing connection type. If needed, create new connection type first. Current Value: '${name}'`,
  },
  NOTES: {
    type: 'string',
    isValid: (value) => /^.{0,255}$/.test(value),
    invalidMsg: (value) => `cannot exceed 255 characters. Current length: ${value.toString().length}.`,
  },
  PORT_STATUS_ID: {
    type:'ignore',
  },
  PORT_STATUS: {
    type: 'lookup',
    lookupTable: 'statuses',
    isValid: (name, lookupTbl, key) => nameIsValid(name, lookupTbl, key),
    invalidMsg: (value) => `must be either 'Y' or 'N'. Current Value: '${value}'`,
  },
  PORT_STATUS_DATE: {
    type: 'date',
    isValid: (date) => date === null || /^\d{4}-[01]\d-[0-3]\d$/.test(date),
    invalidMsg: (date) => `has wrong date format. '${date}' doesn't match 'yyyy-mm-dd'.`,
  },
  PORT_STATUS_TICKET: {
    type: 'string',
    isValid: (value) => /^.{0,25}$/.test(value),
    invalidMsg: (value) => `cannot exceed 25 characters. Current length: ${value.toString().length}.`,
  },
};

const isValidStructure = (headers) => {
  const requiredFields = [
    'CUTSHEET_ID',
    'LAST_MODIFIED_DATE',
    'BUILDING_NAME',
    'CLOSET_NUMBER',
    'PATCH_PANEL',
    'PATCH_PANEL_PORT',
    'STATION_QUAD',
    'STATION_PORT',
    'SYSTEM_NAME',
    'IP_ADDRESS',
    'STACK_SWITCH',
    'SWITCH_PORT',
    'PORT_SPEED',
    'PORT_DUPLEX',
    'VLAN_NUMBER',
    'NETWORK_NAME',
    'CONN_TYPE_NAME',
    'NOTES',
    'PORT_STATUS',
    'PORT_STATUS_DATE',
    'PORT_STATUS_TICKET'];
  return headers.length === requiredFields.length &&
      requiredFields.reduce((valid, field) => valid && headers.includes(field), true);
};

const formatData = (data) => {
  const metaData = [], rows = [];
  const colsData = data.rows[0].filter(e => e !== 'ERRORS');
  const rowsData = data.rows.splice(1);
  if (isValidStructure(colsData)) {
    colsData.forEach(c => metaData.push({name: c}));
    rowsData.forEach(r => {
      const row = {};
      colsData.forEach((c, i) => row[c] = r[i] ? r[i].toString().trim() : null);
      rows.push(row);
    });
  } else {
    return {'error': `Imported table doesn't match required layout. Must match "Open Query Full" exported file column structure.`};
  }

  return {metaData, rows};
};

// TODO: Add check that refTable exists. Otherwise cannot execute isValid on Lookup fields
const validateRow = (row, refTable) => {
  const errors = [];
  Object.keys(row).forEach(key => {
    const field = validationDetails[key];
    const val = row[key];
    switch (field.type) {
      case 'number':
      case 'string':
      case 'date':
        if (!field.isValid(val)) errors.push(`${key} ${field.invalidMsg(val)}`);
        break;

      case 'lookup':
        if (!field.isValid(val, refTable[field.lookupTable], key)) errors.push(`${key} ${field.invalidMsg(val)}`);
        break;

      case 'ignore':
      default:
    }
  });

  return errors;
};

const validateData = (data, refTable) => {
  const validRows = [];
  const errorRows = [];
  const errorSummary = [];
  data.forEach(row => {
    const rowInfo = Object.entries(row)[0];
    const errors = validateRow(row, refTable);

    if (errors.length > 0) {
      const errorMsg = errors.join('\n');
      row.ERRORS = errorMsg;
      errorRows.push(row);

      const summaryRow = {};
      summaryRow[rowInfo[0]] = rowInfo[1];
      summaryRow.ERRORS = errorMsg;
      errorSummary.push(summaryRow);
    } else {
      validRows.push(row);
    }
  });
  return {validRows, errorRows, errorSummary};
};

export {formatData, validateData, validateRow};