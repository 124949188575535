// TODO: Import all of the query pages. E.g.
import Query from './Query.jsx';
import QueryFull from './QueryFull.jsx';
import Cutsheet from './Cutsheet.jsx';
import CutsheetNotes from './CutsheetNotes.jsx';
import LocateIP from './deprecated/LocateIP.jsx';
import Connections from './deprecated/Connections.jsx';
import Panel from './ModifyPanel.jsx';
import PanelEdit from './ModifyPanelEdit.jsx';
import Switch from './ModifySwitch.jsx';
import Upload from './Upload.jsx';
import AddPanel from './AddPanel.jsx';
import Logout from './Logout.jsx';
import Results from './Results.jsx';
import Reference from './Reference.jsx';
import ReferenceEdit from './ReferenceEdit.jsx';
import ReferenceAdd from './ReferenceAdd.jsx';
import Users from './Users.jsx';
import UserEdit from './UserEdit.jsx';
import MainScreen from './MainScreen.jsx';
import UserAdd from './UserAdd.jsx';

const routes = [
  {path: '/query', component: Query},
  {path: '/queryfull', component: QueryFull},
  {path: '/cutsheet', component: Cutsheet},
  {path: '/cutsheetnotes', component: CutsheetNotes},
  {path: '/locateip', component: LocateIP},
  {path: '/connections', component: Connections},
  {path: '/panel/edit', component: PanelEdit},
  {path: '/panel', component: Panel},
  {path: '/switch', component: Switch},
  {path: '/upload', component: Upload},
  {path: '/addpanel', component: AddPanel},
  {path: '/logout', component: Logout},
  {path: '/results', component: Results},
  {path: '/reference/edit', component: ReferenceEdit},
  {path: '/reference/add', component: ReferenceAdd},
  {path: '/reference', component: Reference},
  {path: '/users', component: Users},
  {path: '/user/edit', component: UserEdit},
  {path: '/user/add', component: UserAdd},
  {path: '/', component: MainScreen},
];

export default routes;
