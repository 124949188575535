import db from './db.js';
import {filterClosets, formValues, makeSelectList} from './uiSharedResources.js';
import React from 'react';
import {Form, Button, Col} from 'react-bootstrap';
import './Contents.css';
import {UserContext} from './UserContext.js';

export default class AddPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: null,
      allClosets: null,
      closets: null,
      portOptions: null,
      query: {
        building: null,
        closet_id: null,
        patch_panel: null,
        patch_panel_ports: null,
      },
    };
    this.onChange = this.onChange.bind(this);
    this.updateClosets = this.updateClosets.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('administrator')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    const {showError} = this.props;
    formValues(showError).then(v => {
      const buildings = makeSelectList(v.buildings.rows, 'BUILDING_ID', 'BUILDING_NAME');
      const allClosets = v.closets.rows;
      this.setState({buildings, allClosets});
    });
    formValues(showError, 'port_config').then(v => {
      const portOptions = makeSelectList(v.rows, 'PORT_CONFIG', 'PORT_CONFIG');
      this.setState({portOptions});
    });
  }

  updateClosets(event) {
    const closets = filterClosets(this.state.allClosets, event);
    this.setState({closets});
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'building') this.updateClosets(event);
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {query} = this.state;
    const {username} = this.context;
    query.username = username;
    const {showError, showSuccess} = this.props;
    console.log(`state.query: ${JSON.stringify(this.state.query)}`);
    const result = await db('new-panel', 'POST', query, showError);
    const res = JSON.parse(result);
    if (res !== undefined && res['status'] === 200) {
      showSuccess(res.message);
      console.log(`handleSubmit response: ${JSON.stringify(result)}`);
    }

  }

  render() {
    return (
        <div style={{display: this.context.showNav('administrator') ? 'block' : 'none'}}>
          <div className="ContentTitle">
            New Panel
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                <Form.Group controlId="formBldName">
                  <Form.Label>Building Name:</Form.Label>
                  <Form.Control as="select" onChange={this.onChange} name="building" custom>
                    {this.state.buildings}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                <Form.Group controlId="formClosetNum">
                  <Form.Label>Closet Number:</Form.Label>
                  <Form.Control as="select" onChange={this.onChange} name="closet_id" custom>
                    {this.state.closets}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                <Form.Group controlId="formPanelLabel">
                  <Form.Label>Panel Label:</Form.Label>
                  <Form.Control onChange={this.onChange} name="patch_panel"/>
                </Form.Group>
              </Col>
              <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                <Form.Group controlId="formNumPatchPorts">
                  <Form.Label>Number of Patch Panel Ports:</Form.Label>
                  <Form.Control as="select" onChange={this.onChange} name="patch_panel_ports" custom>
                    {this.state.portOptions}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Button
                className="ExecuteQuery"
                variant="primary"
                size="sm"
                type="submit"
            >
              Save Panel
            </Button>
          </Form>
        </div>
    );
  }
}
AddPanel.contextType = UserContext;