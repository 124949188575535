import React from 'react';
import './Contents.css';
import {Col, Form, Row, FormControl, Button} from 'react-bootstrap';
import db from './db.js';
import {UserContext} from './UserContext.js';

const hiddenColumns = 'USER_ROLE_ID';

const validate = (record) => {
  const errorMessages = [];
  let isValid = true;
  if (![0, 1, 2, 3, 4].includes(record['ROLE_LEVEL'])) {
    isValid = false;
    errorMessages.push('ROLE_LEVEL value is not in [0, 1, 2, 3, 4].');
  }
  if (!['N', 'Y'].includes(record['SET_MANUALLY'])) {
    isValid = false;
    errorMessages.push('SET_MANUALLY value is not in ["Y", "N"].');
  }
  return { isValid, invalidMsg: errorMessages.join('\n')};
};

export default class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: this.props.location.state.record,
    };
    this.makeFieldRows = this.makeFieldRows.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('user-management')) {
        this.props.history.push({
          pathname: '/',
        });
      }}, 100);
  }

  formatRow(field) {
    const {record} = this.state;

    switch (field) {
      case 'ROLE_LEVEL':
        return (<FormControl id={field} name={field} value={record[field]} as="select" onChange={this.onChange}
                             custom>
          <option key={0} value={0}>No Access (0)</option>
          <option key={1} value={1}>Reports (1)</option>
          <option key={2} value={2}>Advanced Reports (2)</option>
          <option key={3} value={3}>Modify and Admin (3)</option>
          <option key={4} value={4}>User Management (4)</option>
        </FormControl>);
      case 'SET_MANUALLY':
        return (<FormControl id={field} name={field} value={record[field]} as="select" onChange={this.onChange}
                             custom>
          <option key="Y" value="Y">Y</option>
          <option key="N" value="N">N</option>
        </FormControl>);
      default:
        return <FormControl id={field} name={field} value={record[field]} readOnly plaintext/>;
    }
  }

  makeFieldRows() {
    const {record} = this.state;
    return Object.keys(record)
        .filter(field => !hiddenColumns.includes(field))
        .map(field => {
          const format = this.formatRow(field);
          return (
              <Form.Group as={Row} key={field} className={'align-items-center'}>
                <Col xl={2} lg={3} md={4} sm={5} xs={12}>
                  <Form.Label>{field}</Form.Label>
                </Col>
                <Col xl={3} lg={4} md={5} sm={7} xs={12}>
                  {format}
                </Col>
              </Form.Group>
          );
        });
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const {record} = this.state;
    record[name] = name === 'ROLE_LEVEL' ? parseInt(value) : value;
    this.setState({record});
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showSuccess, showError} = this.props;
    const {record} = this.state;
    if (validate(record).isValid) {
      const query = {
        username: record['USERNAME'],
        role_level: record['ROLE_LEVEL'],
        set_manually: record['SET_MANUALLY'],
        admin_username: this.context.username,
      };
      db('auth', 'PUT', query, showError)
          .then(res => {
            if (res) {
              const msg = `User updated: ${record.table} id: ${record.id} with ${JSON.stringify(
                  record.updates)}. Received ${res} from api.`;
              showSuccess(msg);
              console.log(msg);
            }
            this.props.history.push({
              pathname: '/users',
              state: {
                data: {},
              },
            });
          });
    } else {
      const msg = record.invalidMessage;
      showError(msg);
      console.log(msg);
    }
  }

  render() {
    const fieldRows = this.makeFieldRows();
    return (
        <div style={{display: this.context.showNav('user-management') ? 'block' : 'none'}}>
            <h1>User Edit</h1>
            <Form onSubmit={this.handleSubmit}>
              {fieldRows}
              <Form.Row>
                <Button variant="secondary"  onClick={() => this.props.history
                    .push({pathname: '/users', state: {table: this.state.record.table, data: {}}})}>
                  Cancel
                </Button>
                <Button type="submit" className="ml-3">Submit Update</Button>
              </Form.Row>
            </Form>
        </div>
    );
  }
}
UserEdit.contextType = UserContext;
