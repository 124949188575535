import React from 'react';
import './Contents.css';
import db from './db.js';
import {makeSelectList, validIP} from './uiSharedResources.js';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {UserContext} from './UserContext';

async function stackSwitch(ip_address) {
  const customError = () => console.log('Expect 404 error when IP not in database.');
  const vals = await db('switch', 'GET', {ip_address}, customError);
  return vals != null ? vals.rows : null;
}

async function switchPorts(ip_address, stack_switch, patch_panel, showError) {
  const vals = await db('switch', 'GET', {ip_address, stack_switch}, showError);
  return vals != null && !vals.status ? vals.rows : null;
}

async function runQuery(query, showError) {
  const vals = await db('switch', 'GET', query, showError);
  return vals != null ? vals : null;
}

export default class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        ip_address: '',
        stack_switch: '',
        switch_port: '',
      },
      stacks: [],
      switch_ports: [],
      invalid_query: true,
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.loadPassedQuery = this.loadPassedQuery.bind(this);
    this.loadStacks = this.loadStacks.bind(this);
    this.loadSwitchPorts = this.loadSwitchPorts.bind(this);
    this.queryIsInvalid = this.queryIsInvalid.bind(this);
  }

  loadStacks() {
    const {ip_address} = this.state.query;
    if (ip_address !== '') {
      stackSwitch(ip_address)
          .then(stackRows => {
            if (stackRows !== null && stackRows !== undefined) {
              const stacks = makeSelectList(stackRows, 'STACK_SWITCH', 'STACK_SWITCH');
              this.setState({stacks});
            } else {
              this.setState({stacks: []});
            }
          });
    } else {
      const {query} = this.state;
      query.stack_switch = '';
      const stacks = [];
      this.setState({query, stacks});
    }

  }

  loadSwitchPorts() {
    const {showError} = this.props;
    const {ip_address, stack_switch} = this.state.query;
    if (stack_switch !== '') {
      switchPorts(ip_address, parseInt(stack_switch), showError)
          .then(switchPortRows => {
            if (switchPortRows !== null && switchPortRows !== undefined) {
              const switch_ports = makeSelectList(switchPortRows, 'SWITCH_PORT', 'SWITCH_PORT');
              this.setState({switch_ports});
            }
          });
    } else {
      const {query} = this.state;
      query.switch_port = '';
      const switch_ports = [];
      this.setState({query, switch_ports});
    }
  }

  loadPassedQuery() {
    if (this.props.location.state) {
      const query = this.state.query;
      const passedQuery = this.props.location.state.query;
      Object.keys(query).forEach(key => {
        query[key] = passedQuery[key];
      });
      this.setState({query});
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    this.loadPassedQuery();
    if (this.state.query.ip_address !== '') this.loadStacks();
    if (this.state.query.stack_switch !== '') this.loadSwitchPorts();
    this.queryIsInvalid();
  }

  resetForm() {
    const query = this.state.query;
    Object.keys(query).forEach(key => {
      query[key] = '';
    });
    const stacks = [];
    const switch_ports = [];
    this.setState({query, stacks, switch_ports});
    this.queryIsInvalid();
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showError} = this.props;
    const {query} = this.state;
    runQuery(query, showError)
        .then(res => {
          this.props.history.push({
            pathname: '/panel/edit',
            state: {
              data: res,
              query: query,
              startPage: '/switch',
            },
          });
        });
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'ip_address' && validIP(value)) this.loadStacks();
    if (name === 'stack_switch') this.loadSwitchPorts();
    this.queryIsInvalid();
  }

  queryIsInvalid() {
    const {ip_address, stack_switch, switch_port} = this.state.query;
    const ipIsValid = validIP(ip_address);
    const switchIsValid = stack_switch !== '';
    const portIsValid = switch_port !== '';
    const invalid_query = !ipIsValid || !switchIsValid || !portIsValid;
    this.setState({invalid_query});
  }

  render() {
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <div className={'ContentTitle'}>Modify by Switch Port</div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId={'formIPAddress'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Switch IP Address:</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control onChange={this.onChange} value={this.state.query.ip_address}
                              name={'ip_address'} custom/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formStackSwitch'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Stack Switch:</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.stack_switch}
                              name={'stack_switch'} custom>
                  {this.state.stacks}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formSwitchPort'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Switch Port</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.switch_port}
                              name={'switch_port'} custom>
                  {this.state.switch_ports}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Row>
              <Col>
                <Button onClick={this.resetForm}
                        variant="secondary"
                        size="sm"
                >
                  Reset Form
                </Button>
                <Button
                    className={'ml-3'}
                    variant={'primary'}
                    size={'sm'}
                    type={'submit'}
                    disabled={this.state.invalid_query}
                >
                  Execute Query
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
Switch.contextType = UserContext;