//const config = require('./config-ui.js').get(process.env.NODE_ENV);

//    merged with CSDB-12 and commented.
//    needed to it this way in dev environemnt - with line 9

const configui = require('./config-ui');
const backupShowError = value => {
  const msg = `db.js error: ${value}`;
  console.log(msg);
  return msg;
};

export default async function db(endpoint, method = 'GET', data = {}, showError = backupShowError) {
  const config = configui.get(process.env.REACT_APP_ENV);

  const apiEndpoint = new URL(config.api_endpoints[endpoint]);

  try {
    const req = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
    };

    if (method === 'GET' && data !== {}) {
      apiEndpoint.search = new URLSearchParams(data).toString();
    }

    if (method === 'POST' || method === 'PUT') req.body = JSON.stringify(data);
    const response = await fetch(apiEndpoint.toString(), req);
    const resp = response.clone();

    if (resp.ok && resp.status === 200) {
      const respBody = await response.text();
      const result = req.method === 'GET' ? await JSON.parse(respBody) : respBody;

      if (result.errors && result.errors.length > 0) {
        const error = result.errors;
        if (error.extensions.code === 'BAD_USER_INPUT') {
          const details = error.extensions.exception.errors.join('\n ');
          console.log(`db.js error: ${details}`);
          showError(`db.js response contained errors: ${details}`);
        }
      }
      return result;
    } else {
      const body = await resp.text();
      const res = await JSON.parse(body) || resp.message;
      const message = `Server responded with status: ${resp.status} and message: ${res.message}`;
      showError(message);
      return {status: resp.status, message};
    }
  } catch (err) {
    showError(`Error in processing server response: ${err.message}`);
    console.log(`Error in processing server response: ${err}`);
    return err;
  }
}