import React from 'react';
import Toast from './Toast.jsx';

export default function withToast(OriginalComponent) {
  return class ToastWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        toastVisible: false, toastMessage: '', toastType: 'success', header: '',
      };
      this.showSuccess = this.showSuccess.bind(this);
      this.showError = this.showError.bind(this);
      this.showWarning = this.showWarning.bind(this);
      this.dismissToast = this.dismissToast.bind(this);
    }

    showSuccess(message) {
      this.setState(
          {toastVisible: true, toastMessage: message, toastType: 'success', header: 'Success', dismissible: true});
      window.setTimeout(() => this.dismissToast(), 5000);
    }

    showError(message) {
      this.setState(
          {toastVisible: true, toastMessage: message, toastType: 'danger', header: 'Error(s)', dismissible: true});
      window.setTimeout(() => this.dismissToast(), 5000);
    }

    showWarning(message, accept) {
      this.setState(
          {toastVisible: true, toastMessage: message, toastType: 'warning', header: 'Warning(s)', dismissible: false, accept});
    }

    dismissToast() {
      this.setState({toastVisible: false});
    }

    render() {
      const {toastType, toastVisible, toastMessage, header, dismissible, accept} = this.state;
      return (
          <React.Fragment>
            <Toast
                variant={toastType}
                showing={toastVisible}
                dismiss={this.dismissToast}
                header={header}
                dismissible={dismissible}
                accept={accept}
            >
              {toastMessage}
            </Toast>
            <OriginalComponent
                showError={this.showError}
                showSuccess={this.showSuccess}
                showWarning={this.showWarning}
                toastVisible={this.state.toastVisible}
                // dismissToast={this.dismissToast}
                {...this.props}
            />
          </React.Fragment>
      );
    }
  };
}