import React from 'react';
import './Contents.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {hiddenColumns, ReferenceTableRecord} from './ReferenceTable.js';

function CleanupRows(idName, rows, table) {
  rows.forEach(row => {
    const date = new Date(row.LAST_MODIFIED_DATE);
    row.LAST_MODIFIED_DATE = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

    const thisRow = {...row};
    row.EDIT = <Button
        as={Link}
        className="ExecuteQuery"
        variant="outline-dark"
        size="sm"
        key={row[idName]}
        to={{pathname: '/reference/edit', state: {record: new ReferenceTableRecord(table, thisRow)}}}
    >Edit</Button>;
  });
}

export default function ReferenceTable({headers, rows, table}) {
  const hiddenCols = hiddenColumns(table);
  if (headers === undefined || rows === undefined) {
    return (
        <div/>
    );
  } else {
    const myColumns = headers.map(cell => {
      return {Header: cell.name, accessor: cell.name, show: !hiddenCols[cell.name]};
    });

    const idName = headers[0].name;

    myColumns.push({Header: 'Edit', accessor: 'EDIT'});

    CleanupRows(idName, rows, table);

    return (
        <ReactTable
            data={rows}
            columns={myColumns}
            defaultPageSize={50}
            style={{height: '500px'}}
            className="-striped -highlight"
        />
    );
  }
}
