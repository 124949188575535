import React from 'react';
import './Contents.css';
import db from './db.js';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {UserContext} from './UserContext';
import {validateRow} from './Upload.js';
import {formValues, makeSelectList, formatDateField} from './uiSharedResources.js';

export default class PanelEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data.rows[0] || {},
      query: this.props.location.state.query || {},
      startPage: this.props.location.state.startPage || {},
      port_speeds: [],
      port_duplexes: [],
      networks: [],
      connections: [],
      statuses: [],
      updates: {},
    };
    this.onChange = this.onChange.bind(this);
    this.loadReferenceTables = this.loadReferenceTables.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loadReferenceTables() {
    const {showError} = this.props;
    formValues(showError, 'port_speed').then(response => {
      if (response !== null && response !== undefined) {
        const port_speeds = makeSelectList(response.rows, 'PORT_SPEED_ID', 'PORT_SPEED');
        this.setState({port_speeds});
      }
    });
    formValues(showError, 'port_duplex').then(response => {
      if (response !== null && response !== undefined) {
        const port_duplexes = makeSelectList(response.rows, 'PORT_DUPLEX_ID', 'PORT_DUPLEX');
        this.setState({port_duplexes});
      }
    });
    formValues(showError, 'network').then(response => {
      if (response !== null && response !== undefined) {
        const networks = makeSelectList(response.rows, 'NETWORK_ID', 'NETWORK_NAME');
        this.setState({networks});
      }
    });
    formValues(showError, 'connection').then(response => {
      if (response !== null && response !== undefined) {
        const connections = makeSelectList(response.rows, 'CONN_TYPE_ID', 'CONN_TYPE_NAME');
        this.setState({connections});
      }
    });
    formValues(showError, 'port_status').then(response => {
      if (response !== null && response !== undefined) {
        const statuses = makeSelectList(response.rows, 'PORT_STATUS_ID', 'PORT_STATUS');
        this.setState({statuses});
      }
    });
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    this.loadReferenceTables();
    const {data} = this.state;
    formatDateField(data);
    // data.PORT_DISABLE = data.PORT_DISABLE === 'Y';
    this.setState({data});
  }

  onChange(event, naturalValue) {
    const {name, value: textValue, type, checked} = event.target;
    const value = type === 'checkbox' ? checked : naturalValue === undefined ? textValue : naturalValue;
    const {data} = this.state;
    data[name] = value === '' ? null : value;
    this.setState({data});
  }

  handleSubmit(event) {
    event.preventDefault();
    const {data, networks, port_speeds, port_duplexes, connections, statuses} = this.state;
    const ref = {networks, port_speeds, port_duplexes, connections, statuses};
    const {username} = this.context;
    const {showSuccess, showError} = this.props;
    const updates = Object.fromEntries(Object.entries(data));
    ['BUILDING_NAME', 'CLOSET_NUMBER', 'PATCH_PANEL', 'PATCH_PANEL_PORT'].forEach(field => delete updates[field]);
    // updates.PORT_DISABLE = updates.PORT_DISABLE ? 'Y' : 'N';
    const validationErrors = validateRow(updates, ref);
    if (validationErrors.length > 0) {
      showError(validationErrors.join('\n'));
    } else {
      showSuccess('No problems with updates. About to send update request.');
      updates.USERNAME = username;
      db('update', 'PUT', {updates}, showError)
          .then(res => {
            const resp = JSON.parse(res);
            const msg = `Update submitted for CUTSHEET_ID ${updates.CUTSHEET_ID}. \nReceived ${resp.message} from api.`;
            resp.status === 200 ? showSuccess(msg) : showError(msg);
          });
    }
  }

  render() {
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <h1>Edit Cutsheet Record</h1>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId={'formCutsheetId'}>
              <Form.Label as={Col} sm={'auto'}>Cutsheet_ID: </Form.Label>
              <Col sm={'auto'}>
                <Form.Control plaintext readOnly value={this.state.data.CUTSHEET_ID}/>
              </Col>
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formBuilding'}>
                <Form.Label>Building Name</Form.Label>
                <Form.Control readOnly value={this.state.data.BUILDING_NAME}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formCloset'}>
                <Form.Label>Closet Number</Form.Label>
                <Form.Control readOnly value={this.state.data.CLOSET_NUMBER}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formPanelLabel'}>
                <Form.Label>Panel Label</Form.Label>
                <Form.Control readOnly value={this.state.data.PATCH_PANEL}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formPanelPort'}>
                <Form.Label>Patch Panel Port</Form.Label>
                <Form.Control readOnly value={this.state.data.PATCH_PANEL_PORT}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formQuadLabel'}>
                <Form.Label>Quad Label</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.STATION_QUAD} name={'STATION_QUAD'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formQuadPort'}>
                <Form.Label>Quad Port</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.STATION_PORT} name={'STATION_PORT'}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formSystemName'}>
                <Form.Label>System Name</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.SYSTEM_NAME || ''} name={'SYSTEM_NAME'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formIpAddress'}>
                <Form.Label>Switch IP Address</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.IP_ADDRESS || ''} name={'IP_ADDRESS'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formSwitchStack'}>
                <Form.Label>Switch Stack</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.STACK_SWITCH || ''}
                              name={'STACK_SWITCH'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formSwitchPort'}>
                <Form.Label>Switch Port</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.SWITCH_PORT || ''} name={'SWITCH_PORT'}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formPortSpeed'}>
                <Form.Label>Port Speed</Form.Label>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.data.PORT_SPEED_ID}
                              name={'PORT_SPEED_ID'} custom>
                  {this.state.port_speeds}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formPortDuplex'}>
                <Form.Label>Port Duplex</Form.Label>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.data.PORT_DUPLEX_ID}
                              name={'PORT_DUPLEX_ID'} custom>
                  {this.state.port_duplexes}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formVLAN'}>
                <Form.Label>VLAN Number</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.VLAN_NUMBER || ''} name={'VLAN_NUMBER'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formNetwork'}>
                <Form.Label>Network Name</Form.Label>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.data.NETWORK_ID}
                              name={'NETWORK_ID'} custom>
                  {this.state.networks}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formConnType'}>
                <Form.Label>Connection Type</Form.Label>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.data.CONN_TYPE_ID}
                              name={'CONN_TYPE_ID'} custom>
                  {this.state.connections}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={6} sm={7} xs={12} controlId={'formNotes'}>
                <Form.Label>Notes</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.NOTES || ''} name={'NOTES'}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formPortStatus'}>
                <Form.Label>Port Status</Form.Label>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.data.PORT_STATUS_ID}
                              name={'PORT_STATUS_ID'} custom>
                  {this.state.statuses}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formStatusDate'}>
                <Form.Label>Date Disabled</Form.Label>
                <Form.Control placeholder={'yyyy-mm-dd'} onChange={this.onChange}
                              value={this.state.data.PORT_STATUS_DATE || ''} name={'PORT_STATUS_DATE'}/>
              </Form.Group>
              <Form.Group as={Col} xl={2} lg={3} md={4} sm={6} xs={12} controlId={'formStatusTicket'}>
                <Form.Label>Trouble Ticket:</Form.Label>
                <Form.Control onChange={this.onChange} value={this.state.data.PORT_STATUS_TICKET || ''}
                              name={'PORT_STATUS_TICKET'}/>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Col>
                <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => this.props.history.push({
                      pathname: this.state.startPage,
                      state: {
                        query: this.state.query,
                      },
                    })
                    }
                >
                  Go Back to Query
                </Button>
                <Button className={'ml-3'} variant={'primary'} size={'sm'} type={'submit'}>Save Changes</Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
PanelEdit.contextType = UserContext;