import React from 'react';
import {Form, Button} from 'react-bootstrap';
import ReferenceTable from './ReferenceTable.jsx';
import db from './db.js';
import {UserContext} from './UserContext';

const listItems = [
  {value: '', name: ''},
  {value: 'building', name: 'Building'},
  {value: 'closet', name: 'Closet'},
  {value: 'connection', name: 'Connection Type'},
  {value: 'network', name: 'Network'},
  {value: 'port_speed', name: 'Port Speed'},
  {value: 'port_duplex', name: 'Port Duplex'},
  {value: 'port_status', name: 'Port Status'},
  {value: 'port_config', name: 'Port Config'},
];

const list = listItems.map(i => <option key={i.value} value={i.value}>{i.name}</option>);

export default class Reference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: this.props.location.state ? this.props.location.state.table : '',
      data: this.props.location.state ? this.props.location.state.data : {},
    };
    this.onChange = this.onChange.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData(table) {
    if (table === '') {
      this.setState({table: '', data: {}});
    } else {
      const {showError} = this.props;
      db('reference', 'GET', {table: table}, showError)
          .then(res => {
            if (res === null) {
              const msg = 'Could not reach the database';
              console.log(msg);
              showError(msg);
            } else {
              this.setState({table: table, data: res});
            }
          });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    if (this.state.table !== '') {
      this.loadData(this.state.table);
    }
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (!this.props.toastVisible && prevProps.toastVisible) {
      this.loadData(this.state.table);
    }
  }

  onChange(event) {
    const {value} = event.target;
    this.loadData(value);
  }

  render() {
    const {metaData, rows} = this.state.data;
    const table = this.state.table;
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <h1>Reference Table Maintenance</h1>
          <Form inline>
            <Form.Label className="my-1 mr-2">Select a Reference Table to Maintain:</Form.Label>
            <Form.Control as="select" value={table} onChange={this.onChange} className="my-1 mr-sm-2" custom>
              {list}
            </Form.Control>
            <Button variant="primary" hidden={table === ''} onClick={() => {
              this.props.history.push({
                pathname: '/reference/add',
                state: {
                  table,
                  // record: new ReferenceTableRecord(table, {}),
                },
              });
            }}>Add new {table}</Button>
          </Form>
          <ReferenceTable headers={metaData} rows={rows} table={table}/>
        </div>
    );
  }
}
Reference.contextType = UserContext;
