import React from 'react';
import {Alert, Button} from 'react-bootstrap';

const NewLines = (msgs) => {
  let newMsgs;
  if (typeof msgs === 'string') {
    newMsgs = msgs.split('\n').map((line, i) => <p key={i}>{line}</p>);
  } else if (Array.isArray(msgs)) {
    newMsgs = msgs.map((line, i) => <p key={i}>{line}</p>);
  }
  return newMsgs;
};

export default class Toast extends React.Component {

  render() {
    const {showing, variant, dismiss, children, header, dismissible, accept} = this.props;
    const Buttons = () => (
        <div className={'d-flex justify-content-end'}>
          <Button key={'cancel'}
                  onClick={() => {
                    dismiss();
                    accept('cancel');
                  }}
                  variant={'outline-warning'}>
            Cancel
          </Button>
          <Button key={'accept'}
                  onClick={() => {
                    dismiss();
                    accept('accept');
                  }}
                  variant={'warning'}
                  className={'ml-3'}>
            I Understand
          </Button>
        </div>
    )
    return (
        <Alert show={showing} variant={variant} onClose={dismiss} transition={null} dismissible={dismissible}>
          <Alert.Heading>{header}</Alert.Heading>
          {NewLines(children)}
          <div id={'buttons'}>
            {variant === 'warning' ? <Buttons /> : null}
          </div>
        </Alert>
    );
  }
}