const referenceTablesDetails = {
  building: {
    id: 'BUILDING_ID',
    name: 'BUILDING_NAME',
    'BUILDING_ID': {
      hideColInTable: true,
    },
    'BUILDING_NAME': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,50}$/,
      invalidMsg: 'between 1 and 50 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  closet: {
    id: 'CLOSET_ID',
    name: 'CLOSET_NAME',
    'CLOSET_ID': {
      hideColInTable: true,
    },
    'BUILDING_ID': {
      hideColInTable: true,
      requiredForAdd: true,
      type: 'select',
      validValues: /[0-9]+/,
      invalidMsg: 'a number',
    },
    'CLOSET_NUMBER': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,50}$/,
      invalidMsg: 'between 1 and 50 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  connection: {
    id: 'CONN_TYPE_ID',
    name: 'CONN_TYPE_NAME',
    'CONN_TYPE_ID': {
      hideColInTable: true,
    },
    'CONN_TYPE_NAME': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,10}$/,
      invalidMsg: 'between 1 and 10 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/, invalidMsg: 'either "Y" or "N"',
    },
  },
  network: {
    id: 'NETWORK_ID',
    name: 'NETWORK_NAME',
    'NETWORK_ID': {
      hideColInTable: true,
    },
    'NETWORK_NAME': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,40}$/,
      invalidMsg: 'between 1 and 40 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  port_speed: {
    id: 'PORT_SPEED_ID',
    name: 'PORT_SPEED',
    'PORT_SPEED_ID': {
      hideColInTable: true,
    },
    'PORT_SPEED': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,10}$/,
      invalidMsg: 'between 1 and 10 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  port_duplex: {
    id: 'PORT_DUPLEX_ID',
    name: 'PORT_DUPLEX',
    'PORT_DUPLEX_ID': {
      hideColInTable: true,
    },
    'PORT_DUPLEX': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,10}$/,
      invalidMsg: 'between 1 and 10 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  port_status: {
    id: 'PORT_STATUS_ID',
    name: 'PORT_STATUS',
    'PORT_STATUS_ID': {
      hideColInTable: true,
    },
    'PORT_STATUS': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^.{1,20}$/,
      invalidMsg: 'between 1 and 20 characters',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
  port_config: {
    id: 'PORT_CONFIG_ID',
    name: 'PORT_CONFIG',
    'PORT_CONFIG_ID': {
      hideColInTable: true,
    },
    'PORT_CONFIG': {
      userEditable: true,
      requiredForAdd: true,
      type: 'string',
      validValues: /^[0-9]+$/,
      invalidMsg: 'a positive integer',
    },
    'ACTIVE': {
      userEditable: true,
      type: 'select',
      validValues: /[YN]/,
      invalidMsg: 'either "Y" or "N"',
    },
  },
};

const refFields = (table) => {
  const result = {};
  result.id = referenceTablesDetails[table].id;
  result.name = referenceTablesDetails[table].name;
  return result;
};

const userInputFields = (table) => {
  const result = [];
  const tableFields = referenceTablesDetails[table];
  Object.keys(tableFields).forEach(key => {
    if (tableFields[key].requiredForAdd) {
      result.push(key);
    }
  });
  return result;
};

const editableFieldsForTable = (table) => {
  const fields = [];
  Object.keys(referenceTablesDetails[table]).forEach(fieldKey => {
    const field = referenceTablesDetails[table][fieldKey];
    if (typeof field !== 'string' && field.userEditable) fields.push(fieldKey);
  });
  return fields;
};

const hiddenColumns = (table) => {
  const result = {};
  if (table !== undefined && table !== '') {
    if (referenceTablesDetails[table]) {
      const tableFields = referenceTablesDetails[table];
      Object.keys(tableFields).forEach(field => {
        if (tableFields[field].hideColInTable) {
          result[field] = true;
        }
      });
    } else {
      console.log(`ReferenceTable.js referenceTableDetails don't exist for table: ${table}`);
    }
  }
  return result;
};

const validateFields = (table, fields) => {
  const result = {
    isValid: true,
    msg: '',
  };
  if (fields === undefined || Object.entries(fields).length === 0) {
    result.isValid = false;
    result.msg = 'Nothing entered, so nothing to execute.';
  } else {
    Object.keys(fields).forEach(fieldKey => {
      if (!fields[fieldKey].toString().match(referenceTablesDetails[table][fieldKey].validValues)) {
        result.isValid = false;
        result.msg += `Error in ${fieldKey}: Value must be ${referenceTablesDetails[table][fieldKey].invalidMsg}.`;
      }
    });
  }
  return result;
};

function validateUnique(newRecord, table, validation, buildingList) {
  const result = {isUnique: true, msg: ''};

  if (Object.keys(newRecord).length === 0) {
    return result;
  }
  if (table === 'closet') {
    validation.forEach(closet => {
      if (result.isUnique && closet.BUILDING_ID.toString() === newRecord.BUILDING_ID && closet.CLOSET_NUMBER ===
          newRecord.CLOSET_NUMBER) {
        result.isUnique = false;
        const buildingName = buildingList.filter(
            b => b.BUILDING_ID.toString() === newRecord.BUILDING_ID)[0].BUILDING_NAME;
        result.msg = `Invalid Input: A ${table} record already exists for Building: ${buildingName} and Closet: ${newRecord.CLOSET_NUMBER}`;
      }
    });
  } else {
    validation.forEach(recordToCheck => {
      const newName = newRecord[refFields(table).name];
      const existingName = recordToCheck[refFields(table).name].toString();
      if (result.isUnique && newName === existingName) {
        result.isUnique = false;
        result.msg = `Invalid Input: A record with the name: ${newName} already exists in table: ${table}.`;
      }
    });
  }
  return result;
}

export class ReferenceTableRecord {
  constructor(tableName, row) {
    this.tableName = tableName;
    this.row = row;
    this.originallyActive = row['ACTIVE'] === 'Y';
    this.cascadeDeactivate = false;
    this.validation = {};
    this.buildingList = {};
    this.inputFields = userInputFields(tableName);
    this.editableFields = editableFieldsForTable(tableName);
    this.hiddenCols = hiddenColumns(tableName);
    this.validStatus = {};
    this.uniqueStatus = {};
  }

  get rowValues() {
    return this.row;
  }

  get table() {
    return this.tableName;
  }

  get fieldKeys() {
    return Object.keys(this.row);
  }

  value(field) {
    return this.row[field] ? this.row[field] : '';
  }

  updateValue(name, value) {
    if (name && value) this.row[name] = value;
  }

  addValidation(val) {
    if (val.rows) this.validation = val.rows;
  }

  addBuildingList(val) {
    if (val.rows) this.buildingList = val.rows;
  }

  get buildings() {
    return this.buildingList;
  }

  get id() {
    return this.row[referenceTablesDetails[this.tableName].id].toString();
  }

  get editableFieldsForTable() {
    return this.editableFields;
  };

  get updates() {
    const updates = {};
    this.fieldKeys.forEach(field => {
      if (this.editableFields.includes(field)) updates[field] = this.row[field];
    });
    return updates;
  }

  get newRecord() {
    const updates = {};
    this.fieldKeys.forEach(field => {
      if (this.inputFields.includes(field)) updates[field] = this.row[field];
    });
    return updates;
  }

  get hiddenColumns() {
    return this.hiddenCols;
  };

  get refId() {
    return referenceTablesDetails[this.tableName].id;
  }

  get buildingRefId() {
    return referenceTablesDetails['building'].id;
  }

  get refName() {
    return referenceTablesDetails[this.tableName].name;
  }

  get buildingRefName() {
    return referenceTablesDetails['building'].name;
  }

  get isValid() {
    const status = validateFields(this.tableName, this.updates);
    this.validStatus.isValid = status.isValid;
    this.validStatus.msg = status.msg;
    return status.isValid;
  }

  get invalidMessage() {
    return this.validStatus.msg;
  };

  get isUnique() {
    const status = validateUnique(this.updates, this.tableName, this.validation, this.buildingList);
    this.uniqueStatus.isUnique = status.isUnique;
    this.uniqueStatus.msg = status.msg;
    return status.isUnique;
  }

  get unUniqueMessage() {
    return this.uniqueStatus.msg;
  }

  isCascadeDeactivation(name, value) {
    this.cascadeDeactivate = (this.tableName === 'building' || this.tableName === 'closet') &&
        name === 'ACTIVE' && value === 'N' && this.originallyActive;
    return this.cascadeDeactivate;
  }

  updateQueryData(username) {
    return {id: this.id, updates: this.updates, username, table: this.tableName, cascadeDeactivate: this.cascadeDeactivate};
  }

  newRecordQueryData(username) {
    return {newRecord: this.newRecord, table: this.tableName, username};
  }
}

export {editableFieldsForTable, hiddenColumns};