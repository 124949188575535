import React from 'react';
import './Contents.css';
import db from './db.js';
import {filterClosets, formValues, makeSelectList, patchPanels} from './uiSharedResources.js';
import {Form, Button, Col, Row} from 'react-bootstrap';
import {UserContext} from './UserContext';

async function patchPanelPorts(closet_id, patch_panel, showError) {
  const vals = await db('panel-ports', 'GET', {closet_id, patch_panel}, showError);
  return vals != null ? vals.rows : null;
}

async function runQuery(query, showError) {
  const vals = await db('panel', 'GET', query, showError);
  return vals != null ? vals : null;
}

export default class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        building_id: '',
        closet_id: '',
        patch_panel: '',
        patch_panel_port: '',
      },
      buildings: [],
      closets: [],
      allClosets: [],
      panels: [],
      panelPorts: [],
      invalid_query: true,
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.loadBuildings = this.loadBuildings.bind(this);
    this.loadClosets = this.loadClosets.bind(this);
    this.loadPassedQuery = this.loadPassedQuery.bind(this);
    this.loadPatchPanels = this.loadPatchPanels.bind(this);
    this.queryIsInvalid = this.queryIsInvalid.bind(this);
  }

  loadBuildings() {
    const {showError} = this.props;
    formValues(showError, 'building')
        .then(response => {
          if (response !== null && response !== undefined) {
            const buildings = makeSelectList(response.rows, 'BUILDING_ID', 'BUILDING_NAME');
            this.setState({buildings});
          }
        });
  }

  loadClosets() {
    const {showError} = this.props;
    formValues(showError, 'closet')
        .then(response => {
          if (response !== null && response !== undefined) {
            const allClosets = response.rows;
            this.setState({allClosets});
            if (this.state.query.building_id !== '') this.updateClosets();
          }
        });
  }

  updateClosets() {
    const allClosets = this.state.allClosets;
    const building_id = this.state.query.building_id;
    const closets = filterClosets(allClosets, building_id);
    this.setState({closets});
  }

  loadPatchPanels() {
    const {showError} = this.props;
    const {closet_id} = this.state.query;
    const panels = [];
    if (closet_id !== '' && closet_id !== '0') {
      patchPanels(closet_id, showError)
          .then(panelRows => {
            panels.push(makeSelectList(panelRows, 'PATCH_PANEL', 'PATCH_PANEL'));
            this.setState({panels});
          });
    } else {
      this.setState({panels});
    }
  }

  loadPanelPorts() {
    const {showError} = this.props;
    const {closet_id, patch_panel} = this.state.query;
    const panelPorts = [];
    if (closet_id !== '' && closet_id !== '0' && patch_panel !== '' && patch_panel !== '0') {
      patchPanelPorts(closet_id, patch_panel, showError)
          .then(panelPortRows => {
            panelPorts.push(makeSelectList(panelPortRows, 'PATCH_PANEL_PORT', 'PATCH_PANEL_PORT'));
            this.setState({panelPorts});
          });
    } else {
      this.setState({panelPorts});
    }
  }

  loadPassedQuery() {
    if (this.props.location.state) {
      const query = this.state.query;
      const passedQuery = this.props.location.state.query;
      Object.keys(query).forEach(key => {
        query[key] = passedQuery[key];
      });
      this.setState({query});
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.context.showNav('modify')) {
        this.props.history.push({
          pathname: '/',
        });
      }
    }, 100);
    this.loadBuildings();
    this.loadClosets();
    this.loadPassedQuery();
    if (this.state.query.closet_id !== '') this.loadPatchPanels();
    if (this.state.query.patch_panel !== '') this.loadPanelPorts();
    this.queryIsInvalid();
  }

  onChange(event, naturalValue) {
    const {name, value: textValue} = event.target;
    const value = naturalValue === undefined ? textValue : naturalValue;
    const query = this.state.query;
    query[name] = value;
    this.setState({query});
    if (name === 'building_id') this.updateClosets();
    if (name === 'closet_id') this.loadPatchPanels();
    if (name === 'patch_panel') this.loadPanelPorts();
    this.queryIsInvalid();
  }

  resetForm() {
    const query = this.state.query;
    Object.keys(query).forEach(key => {
      query[key] = '';
    });
    this.setState({query});
    this.updateClosets();
    this.loadPatchPanels();
    this.loadPanelPorts();
    this.queryIsInvalid();
  }

  handleSubmit(event) {
    event.preventDefault();
    const {showError} = this.props;
    const fullQuery = this.state.query;
    const query = {};
    Object.keys(fullQuery).forEach(key => {
      const value = fullQuery[key];
      if (value !== '' && value !== '0') query[key] = value;
    });
    runQuery(query, showError).then(res => {
      this.props.history.push({
        pathname: '/panel/edit',
        state: {
          data: res,
          query: query,
          startPage: '/panel',
        },
      });
    });
  }

  queryIsInvalid() {
    const {building_id, closet_id, patch_panel, patch_panel_port} = this.state.query;
    const buildingIsValid = building_id !== '';
    const closetIsValid = closet_id !== '';
    const panelIsValid = patch_panel !== '';
    const portIsValid = patch_panel_port !== '';
    const invalid_query = !buildingIsValid || !closetIsValid || !panelIsValid || !portIsValid;
    this.setState({invalid_query});
  }

  render() {
    return (
        <div style={{display: this.context.showNav('modify') ? 'block' : 'none'}}>
          <div className={'ContentTitle'}>Modify by Panel Port</div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId={'formBuldingName'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Building Name</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.building_id}
                              name={'building_id'} custom>
                  {this.state.buildings}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formClosetNumber'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Closet Number</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.closet_id}
                              name={'closet_id'} custom>
                  {this.state.closets}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formPatchPanel'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Patch Panel Label</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.patch_panel}
                              name={'patch_panel'} custom>
                  {this.state.panels}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'formPatchPanelPort'}>
              <Form.Label as={Col} xxl={1} lg={2} md={3} xs={4}>Patch Panel Port</Form.Label>
              <Col sm={'auto'} xs={12}>
                <Form.Control as={'select'} onChange={this.onChange} value={this.state.query.patch_panel_port}
                              name={'patch_panel_port'} custom>
                  {this.state.panelPorts}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Row>
              <Col>
                <Button onClick={this.resetForm}
                        variant="secondary"
                        size="sm"
                >
                  Reset Form
                </Button>
                <Button
                    className={'ml-3'}
                    variant={'primary'}
                    size={'sm'}
                    type={'submit'}
                    disabled={this.state.invalid_query}
                >
                  Execute Query
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
    );
  }
}
Panel.contextType = UserContext;